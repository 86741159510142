<template>
  <div class="wrap-comment">
    <from-bottom-dialog
      :page-id="pageId"
      :modelValue="modelValue"
      @update:modelValue="(e) => $emit('update:modelValue', e)"
      @cancel="cancel"
      :show-heng-gang="false"
      maskMode="light"
      :height="height"
      tag="comment"
      mode="white"
    >
      <template v-slot:header>
        <div class="title">
          <dy-back mode="dark" img="close" direction="right" style="opacity: 0" />
          <div class="num" @click="cancel">Hỗ trợ khác hàng</div>
          <div class="right">
            <Icon icon="ic:round-close" @click="cancel" />
          </div>
        </div>
      </template>
      <template>
        <div class="chat-wrap">
          <div v-if="this.departments.length" class="bottom-chat">
            <div class="logo text-center">
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-v-79f2e8f9=""
              >
                <path
                  d="M8.20933 0.690323C7.92753 0.532133 7.63077 0.402205 7.3234 0.302437C6.64004 0.0925673 5.92808 -0.00925343 5.21329 0.000659985C1.99151 0.000659985 0.125 1.79037 0.125 4.57772V17.8202H2.37474V4.57695C2.37474 1.43196 3.78044 0.358292 5.21252 0.358292C5.64049 0.349206 6.06337 0.452525 6.43894 0.657939C6.81451 0.863352 7.12964 1.16367 7.35288 1.52893C7.78188 2.1961 8.05107 3.1821 8.05107 4.57539V17.4532H8.55765C8.88182 17.4581 9.20358 17.3968 9.50328 17.2731C9.80298 17.1495 10.0743 16.9661 10.3008 16.7341V4.57695C10.3031 2.81672 9.56848 1.45524 8.20933 0.690323Z"
                  fill="url(#paint0_radial_537_1981)"
                  data-v-79f2e8f9=""
                ></path>
                <path
                  d="M11.9124 0.00117832H9.40281C8.79217 -0.00348425 8.18359 0.0726866 7.59294 0.227705C7.83369 0.316375 8.06714 0.423759 8.29113 0.548874C8.32139 0.565941 8.35087 0.583785 8.38035 0.601628C8.71562 0.443124 9.0824 0.362501 9.45324 0.365792H11.3756V13.4065C11.3756 14.9984 11.0141 16.0721 10.4664 16.7424C10.4136 16.8067 10.3593 16.868 10.3035 16.9247C10.077 17.1567 9.80565 17.3401 9.50595 17.4637C9.20625 17.5874 8.8845 17.6486 8.56033 17.6438H6.63796V4.59763C6.63796 3.30675 6.8769 2.35798 7.25858 1.68848L7.21669 1.61866C7.00957 1.2871 6.72428 1.01141 6.38583 0.81574C6.35713 0.799448 6.32765 0.785486 6.29817 0.770746C5.07167 1.51161 4.36572 2.74974 4.36572 4.3137V17.9999H8.6123C9.18187 18.0036 9.74977 17.9379 10.3035 17.8044C12.3864 17.2877 13.6478 15.7781 13.6478 13.6874V0.00117832H11.9124Z"
                  fill="url(#paint1_radial_537_1981)"
                  data-v-79f2e8f9=""
                ></path>
                <defs data-v-79f2e8f9="">
                  <radialGradient
                    id="paint0_radial_537_1981"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(24.703 -6.10235) scale(55.1745 55.1745)"
                    data-v-79f2e8f9=""
                  >
                    <stop stop-color="#ECD69F" data-v-79f2e8f9=""></stop>
                    <stop offset="0.15" stop-color="#EBD49D" data-v-79f2e8f9=""></stop>
                    <stop offset="0.23" stop-color="#E8CD95" data-v-79f2e8f9=""></stop>
                    <stop offset="0.3" stop-color="#E3C187" data-v-79f2e8f9=""></stop>
                    <stop offset="0.36" stop-color="#DBB073" data-v-79f2e8f9=""></stop>
                    <stop offset="0.41" stop-color="#D29B5A" data-v-79f2e8f9=""></stop>
                    <stop offset="0.46" stop-color="#C6803B" data-v-79f2e8f9=""></stop>
                    <stop offset="0.5" stop-color="#BE7B3A" data-v-79f2e8f9=""></stop>
                    <stop offset="0.84" stop-color="#7E4F30" data-v-79f2e8f9=""></stop>
                    <stop offset="1" stop-color="#653E2C" data-v-79f2e8f9=""></stop>
                  </radialGradient>
                  <radialGradient
                    id="paint1_radial_537_1981"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(26.7832 -6.16389) scale(54.3491 54.3491)"
                    data-v-79f2e8f9=""
                  >
                    <stop stop-color="#ECD69F" data-v-79f2e8f9=""></stop>
                    <stop offset="0.15" stop-color="#EBD49D" data-v-79f2e8f9=""></stop>
                    <stop offset="0.23" stop-color="#E8CD95" data-v-79f2e8f9=""></stop>
                    <stop offset="0.3" stop-color="#E3C187" data-v-79f2e8f9=""></stop>
                    <stop offset="0.36" stop-color="#DBB073" data-v-79f2e8f9=""></stop>
                    <stop offset="0.41" stop-color="#D29B5A" data-v-79f2e8f9=""></stop>
                    <stop offset="0.46" stop-color="#C6803B" data-v-79f2e8f9=""></stop>
                    <stop offset="0.5" stop-color="#BE7B3A" data-v-79f2e8f9=""></stop>
                    <stop offset="0.84" stop-color="#7E4F30" data-v-79f2e8f9=""></stop>
                    <stop offset="1" stop-color="#653E2C" data-v-79f2e8f9=""></stop>
                  </radialGradient>
                </defs>
              </svg>
              NOBLE
            </div>
            <h3 class="title-chat-group">Chọn nhóm hỗ trợ</h3>
            <ul>
              <li v-for="department in this.departments" :key="department._id">
                <div class="item">
                  <div>
                    <svg
                      data-v-79f2e8f9=""
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="icon iconify iconify--mage"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      style="color: white"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      >
                        <path
                          d="M21.25 7.506v6.607a3.963 3.963 0 0 1-3.964 3.965h-2.643l-2.18 2.18a.636.636 0 0 1-.925 0l-2.18-2.18H6.713a3.964 3.964 0 0 1-3.964-3.965V7.506a3.964 3.964 0 0 1 3.964-3.964h10.572a3.964 3.964 0 0 1 3.964 3.964"
                        ></path>
                        <path
                          d="M12 11.338a.66.66 0 1 0 0-1.321a.66.66 0 0 0 0 1.321m4.625 0a.66.66 0 1 0 0-1.321a.66.66 0 0 0 0 1.321m-9.25 0a.66.66 0 1 0 0-1.321a.66.66 0 0 0 0 1.321"
                        ></path>
                      </g>
                    </svg>
                    <button @click="openChat(department.name)">{{ department.description }}</button>
                    <!--                  <svg class="more-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"-->
                    <!--                        width="1em" height="1em" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31 36L19 24l12-12"></path></svg>-->
                  </div>
                </div>
              </li>
              <!-- Popup (Modal) -->
              <div v-if="showChat" class="modal-overlay" @click.self="closeChat">
                <div class="modal-content">
                  <button class="close-button" @click="closeChat">
                    <Icon icon="ic:round-close" />
                  </button>
                  <iframe v-if="showChat" :src="this.chatUrl" width="100%" height="400px"></iframe>
                </div>
              </div>
            </ul>
          </div>
          <div v-else>
            <p>Không có nhóm hỗ trợ nào.</p>
          </div>
        </div>
      </template>
    </from-bottom-dialog>
  </div>
</template>

<script lang="ts">
import { getDepartments, getChannels } from '@/api/rocketchat'
import { UserDataModel } from '@/models/user-data.model'
import { useBaseStore } from '@/store/pinia'
import { _checkImgUrl, _formatNumber, _no, _time, formatTimestamp, getUserData } from '@/utils'
import { mapState } from 'pinia'
import FromBottomDialog from './dialog/FromBottomDialog.vue'
import bus, { EVENT_KEY } from '@/utils/bus'
export default {
  name: 'Chat',
  components: {
    FromBottomDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      default() {
        return false
      }
    },
    videoId: {
      type: String,
      default: null
    },
    pageId: {
      type: String,
      default: 'home-index'
    },
    height: {
      type: String,
      default: 'calc(var(--vh, 1vh) * 70)'
    },
    projectId: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState(useBaseStore, ['friends'])
  },
  watch: {
    modelValue(newValue) {
      if (newValue) {
        this.comments = []
      } else {
        this.comments = []
      }
    }
  },
  data() {
    return {
      departments: [],
      showChat: false,
      chatUrl: '',
      channels: [],
      comments: [],
      userData: new UserDataModel(),
      isLogin: false,
      unsubscribe: null as (() => void) | null, // Tham chiếu đến hàm unsubscribe
      unsubscribeRepliesList: [] as (() => void)[] // Danh sách unsubscribe cho các replies
    }
  },
  async mounted() {
    const departmentsProxy = await getDepartments()
    this.departments = departmentsProxy.data.departments
    console.log('departments: ', this.departments)

    //const channelsProxy = await getChannels();
    //this.channels = channelsProxy.data.groups
    //console.log('channels: ', this.channels)
  },
  methods: {
    openChat(departmentName) {
      this.chatUrl = `https://chat.noble.vn/livechat?department=${encodeURIComponent(departmentName)}&embed=true&mode=popout`
      this.showChat = true
    },
    closeChat() {
      this.showChat = false
    },

    _no,
    _time,
    _formatNumber,
    _checkImgUrl,
    formatTimestamp,
    authen() {
      const userData: UserDataModel | null = getUserData()
      if (userData) {
        this.isLogin = true
        this.userData = userData
      } else {
        this.isLogin = false
      }
    },
    cancel() {
      console.log('close chat')
      this.$emit('update:modelValue', false)
      this.$emit('close')
      bus.emit(EVENT_KEY.CLOSE_CHATS, null)
    },

    beforeDestroy() {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      // Hủy bỏ tất cả các subscriptions cho replies
      this.unsubscribeRepliesList.forEach((unsubscribeReplies) => {
        if (unsubscribeReplies) {
          unsubscribeReplies()
        }
      })
    }
  }
}
</script>
<style scoped>
/* Styles cho modal (popup) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.app-mobile .close-button {
  top: 3px !important;
}
</style>
<style lang="less" scoped>
@import '../assets/less/index';

.title {
  box-sizing: border-box;
  width: 100%;
  height: 40rem;
  padding: 0 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10rem 10rem 0 0;

  .num {
    width: 100%;
    position: absolute;
    font-size: 16rem;
    font-weight: bold;
    text-align: center;
    left: 0;
    color: @white;
  }

  .right {
    display: flex;
    gap: 12rem;
    position: relative;
    z-index: 9;

    svg {
      color: @white;
      padding: 0;
      height: 26px;
      width: 26px;
      font-size: 16rem;
      border-radius: 50%;
      cursor: pointer;
      path {
        fill: #ccc;
      }
    }
  }
}

.gray {
  color: var(--second-text-color);
}

.comment {
  color: #000;
  width: 100%;
  background: #fff;
  z-index: 5;

  .wrapper {
    width: 100%;
    position: relative;
    padding-bottom: 60rem;
  }

  .items {
    width: 100%;

    .item {
      width: 100%;
      margin-bottom: 15rem;
      .main {
        width: 100%;
        padding: 5rem 0;
        display: flex;

        &:active {
          background: #53535321;
        }

        .head-image {
          margin-left: 15rem;
          margin-right: 10rem;
          width: 37rem;
          height: 37rem;
          border-radius: 50%;
        }
      }

      .replies {
        padding-left: 55rem;

        .reply {
          padding: 5rem 0 5rem 5rem;
          display: flex;

          &:active {
            background: #53535321;
          }

          .head-image {
            margin-right: 10rem;
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
          }
        }

        .more {
          font-size: 13rem;
          margin: 5rem;
          display: flex;
          align-items: center;
          color: gray;

          .gang {
            background: #d5d5d5;
            width: 20rem;
            margin-right: 10rem;
            height: 1px;
          }

          span {
            margin-right: 5rem;
          }

          svg {
            font-size: 10rem;
          }
        }
      }

      .content {
        width: 100%;
        display: flex;
        font-size: 14rem;

        .comment-container {
          flex: 1;
          margin-right: 20rem;

          .name {
            color: var(--second-text-color);
            margin-bottom: 5rem;
            display: flex;
            align-items: center;

            .reply-user {
              margin-left: 5rem;
              width: 0;
              height: 0;
              border: 5rem solid transparent;
              border-left: 6rem solid gray;
            }
          }

          .detail {
            margin-bottom: 5rem;
          }

          .time-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13rem;

            .left {
              display: flex;

              .time {
                color: #c4c3c3;
                margin-right: 10rem;
              }

              .reply-text {
                color: var(--second-text-color);
              }
            }

            .love {
              color: gray;
              display: flex;
              align-items: center;

              &.loved {
                color: rgb(231, 58, 87);
              }

              .love-image {
                font-size: 17rem;
                margin-right: 4rem;
              }

              span {
                word-break: keep-all;
              }
            }
          }
        }
      }
    }
  }

  @normal-bg-color: rgb(35, 38, 47);
  @chat-bg-color: rgb(105, 143, 244);

  .input-toolbar {
    border-radius: 10rem 10rem 0 0;
    background: white;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 3;

    @space-width: 18rem;
    @icon-width: 48rem;

    .call-friend {
      padding-top: 30rem;
      overflow-x: scroll;
      display: flex;
      padding-right: @space-width;

      .friend {
        width: @icon-width;
        position: relative;
        margin-left: @space-width;
        margin-bottom: @space-width;
        font-size: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .avatar {
          width: @icon-width;
          height: @icon-width;
          border-radius: 50%;
        }

        span {
          margin-top: 5rem;
          text-align: center;
          width: @icon-width;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .checked {
          position: absolute;
          top: @icon-width - 1.5;
          right: -2px;
          width: 20rem;
          height: 20rem;
          border-radius: 50%;
        }
      }
    }

    .toolbar {
      @icon-width: 25rem;
      display: flex;
      align-items: center;
      padding: 10rem 15rem;
      border-top: 1px solid #e2e1e1;

      .input-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 5rem 10rem;
        background: #eee;
        border-radius: 20rem;

        .right {
          display: flex;
          align-items: center;
        }

        .auto-input {
          width: calc(100% - 160rem);
        }
        input {
          width: 100%;
          border: none;
          background: none;
        }
      }

      img {
        width: @icon-width;
        height: @icon-width;
        border-radius: 50%;
        margin-left: 15rem;
      }
    }
  }
}

.comment-enter-active,
.comment-leave-active {
  transition: all 0.15s ease;
}

.comment-enter-from,
.comment-leave-to {
  transform: translateY(60vh);
}

.input-control {
  width: 100%;
  border: none;
  background: none;
}
.chat-wrap {
  .bottom-chat {
    padding-top: 25px;
    .modal-content {
      height: 100%;
      width: 100%;
      border-radius: 0;
      z-index: 999;
      padding: 0px;
      iframe {
        height: 100%;
        border: none;
        background: #fff;
        // position: relative;
        // z-index: 99;
      }
      .close-button {
        font-size: 26px;
        z-index: 99;
        font-size: 26px;
        z-index: 999;
        color: #fff;
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  h1 {
  }
  .logo {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
    svg,
    img {
      display: block;
      height: 70px;
      width: auto;
      margin: auto;
      margin-bottom: 10px;
    }
  }
  ul {
    max-width: calc(100% - 30px);
    margin: auto;
    li {
      button {
        background: none;
        border: none;
        color: @white;
        font-weight: 600;
      }
      .item {
        color: @white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 0px;
        border-bottom: 1px solid #2e2e2e;
        font-size: 16px;
        position: relative;
        .more-icon {
          transform: rotate(180deg);
          position: absolute;
          right: 0px;
          top: 24px;
          z-index: 3;
        }
        svg {
          color: @white !important;
          width: 22px;
          height: 22px;
        }
        &:hover {
          cursor: pointer;
          button {
            color: #b57538;
          }
          svg {
            color: #b57538 !important;
          }
        }
        & > div {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
      &:last-child {
        .item {
          border-bottom: none;
        }
      }
    }
  }
  .title-chat-group {
    padding-left: 15px;
    padding-right: 15px;
    color: @white !important;
  }
}
iframe {
  height: calc(100vh - 55px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #fff;
  border: none;
  z-index: 99;
  .header__action__Xiqxc {
    display: none;
  }
}
</style>
