<template>
  <from-bottom-dialog
    :page-id="pageId"
    :modelValue="modelValue"
    @update:modelValue="(e) => $emit('update:modelValue', e)"
    @cancel="cancel"
    :show-heng-gang="false"
    maskMode="light"
    :height="height"
    tag="comment"
    mode="white"
  >
    <template v-slot:header>
      <div class="title">
        <dy-back mode="dark" img="close" direction="right" style="opacity: 0" />
        <div class="num">{{ comments?.length }} bình luận</div>
        <div class="right">
          <!-- <Icon icon="prime:arrow-up-right-and-arrow-down-left-from-center" @click.stop="_no" /> -->
          <Icon icon="ic:round-close" v-click="cancel" />
        </div>
      </div>
    </template>
    <div class="comment">
      <div class="wrapper" v-if="comments?.length">
        <div class="items">
          <div class="item" :key="i" v-for="(item, i) in comments as CommentModel[]">
            <!--            v-longpress="(e) => showOptions(item)"-->
            <div class="main">
              <div class="content">
                <img :src="_checkImgUrl(item.avatar)" alt="" class="head-image" />
                <div class="comment-container">
                  <div class="name">{{ item.nickname }}</div>
                  <div class="detail" :class="item.user_buried && 'gray'">
                    {{ item.user_buried ? 'Bình luận này đã bị thu gọn' : item.content }}
                  </div>
                  <div class="time-wrapper">
                    <div class="left">
                      <div class="time">
                        {{ formatTimestamp(item.create_time)
                        }}{{ item.ip_location && ` · ${item.ip_location}` }}
                      </div>
                      <div class="reply-text" @click="reply(item)">Phản hồi</div>
                    </div>
                    <div class="right d-flex" style="gap: 10rem">
                      <div class="love" :class="item?.user_digged && 'loved'" @click="loved(item)">
                        <Icon
                          icon="icon-park-solid:like"
                          v-show="item?.user_digged"
                          class="love-image"
                        />
                        <Icon
                          icon="icon-park-outline:like"
                          v-show="!item?.user_digged"
                          class="love-image"
                        />
                        <span v-if="item.digg_count">{{ _formatNumber(item?.digg_count) }}</span>
                      </div>
                      <div class="love" @click="loved(item, true)">
                        <Icon
                          v-if="item.user_buried"
                          icon="icon-park-solid:dislike-two"
                          class="love-image"
                        />
                        <Icon v-else icon="icon-park-outline:dislike" class="love-image" />
                        <span v-if="item.bury_count">{{ _formatNumber(item?.bury_count) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Phản hồi -->
            <div class="replies" v-if="item?.replies?.length">
              <template v-if="item.showChildren">
                <div class="reply" :key="i" v-for="(child, i) in item.replies">
                  <!--                 v-longpress="e => showOptions(child)"-->
                  <div class="content">
                    <img :src="_checkImgUrl(child?.avatar)" alt="" class="head-image" />
                    <div class="comment-container">
                      <div class="name">
                        {{ child?.nickname }}
                        <div class="reply-user" v-if="child?.replay"></div>
                        {{ child?.replay }}
                      </div>
                      <div class="detail">{{ child?.content }}</div>
                      <div class="time-wrapper">
                        <div class="left">
                          <div class="time">
                            {{ formatTimestamp(child.create_time)
                            }}{{ child.ip_location && ` · ${item.ip_location}` }}
                          </div>
                          <div class="reply-text" @click="reply(child, item)">Phản hồi</div>
                        </div>
                        <div class="right d-flex" style="gap: 10rem">
                          <div
                            class="love"
                            :class="child.user_digged && 'loved'"
                            @click="lovedReply(child, item, false)"
                          >
                            <Icon
                              icon="icon-park-solid:like"
                              v-show="child.user_digged"
                              class="love-image"
                            />
                            <Icon
                              icon="icon-park-outline:like"
                              v-show="!child.user_digged"
                              class="love-image"
                            />
                            <span>{{ _formatNumber(child.digg_count) }}</span>
                          </div>
                          <div class="love" @click="lovedReply(child, item, true)">
                            <Icon
                              v-if="child.user_buried"
                              icon="icon-park-solid:dislike-two"
                              class="love-image"
                            />
                            <Icon v-else icon="icon-park-outline:dislike" class="love-image" />
                            <span v-if="child.bury_count">{{
                              _formatNumber(child?.bury_count)
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <Loading
                v-if="loadChildren && `${loadChildrenItemCId}` === item.id"
                :type="'small'"
                :is-full-screen="false"
              />
              <div class="more" v-else>
                <div v-if="!item.showChildren" @click="handShowChildren(item)">
                  <div class="gang"></div>
                  <span
                    >Hiển thị {{ item.showChildren ? '' : `${item?.replies?.length}` }} bình
                    luận</span
                  >
                  <Icon icon="ep:arrow-down-bold" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <no-more />
      </div>
      <!-- <Loading v-else style="position: absolute" /> -->
      <h5 v-else style="margin-left: 10px">Chưa có bình luận nào</h5>
      <transition name="fade">
        <BaseMask v-if="isCall" mode="lightgray" @click="isCall = false" />
      </transition>
      <div class="input-toolbar">
        <transition name="fade">
          <div class="call-friend" v-if="isCall">
            <div class="friend" :key="i" v-for="(item, i) in friends.all" @click="toggleCall(item)">
              <img
                :style="item.select ? 'opacity: .5;' : ''"
                class="avatar"
                :src="_checkImgUrl(item.avatar)"
                alt=""
              />
              <span>{{ item.name }}</span>
              <img
                v-if="item.select"
                class="checked"
                src="../assets/img/icon/components/check/check-red-share.png"
              />
            </div>
          </div>
        </transition>
        <div class="toolbar">
          <div class="input-wrapper">
            <!-- <AutoInput
              v-model="comment"
              :placeholder="
                selectRow?.id ? `Trả lời ${selectRow.nickname}` : 'Nhập nội dung bình luận'
              "
              ref="autoInput"
              @blur="handleBlur"
              @enter="handleEnter"
            ></AutoInput> -->
            <input
              v-model="comment"
              :placeholder="
                selectRow?.id ? `Trả lời ${selectRow.nickname}` : 'Nhập nội dung bình luận'
              "
              ref="autoInput"
              @blur="handleBlur"
              @enter="handleEnter"
            />
            <div class="right">
              <!-- <img src="../assets/img/icon/message/call.png" @click="isCall = !isCall" /> -->
              <!-- <img src="../assets/img/icon/message/emoji-black.png" @click="_no" /> -->
            </div>
          </div>
          <img v-if="comment" src="../assets/img/icon/message/up.png" @click="send" />
        </div>
      </div>
      <ConfirmDialog title="Thông báo" ok-text="Đồng ý" v-model:visible="showPrivateChat">
        <Search mode="light" v-model="test" :isShowSearchIcon="false" />
      </ConfirmDialog>
    </div>
  </from-bottom-dialog>
</template>

<script lang="ts">
import FirebaseService from '@/api/firebase'
import { COLLECTION_NAMES } from '@/commons/constants'
import { CommentModel } from '@/models/comment.model'
import { UserDataModel } from '@/models/user-data.model'
import { useBaseStore } from '@/store/pinia'
import {
  _checkImgUrl,
  _formatNumber,
  _no,
  _showSelectDialog,
  _sleep,
  _time,
  formatTimestamp,
  getUserData
} from '@/utils'
import {
  arrayRemove,
  arrayUnion,
  increment,
  onSnapshot,
  orderBy,
  query,
  Timestamp
} from 'firebase/firestore'
import { mapState } from 'pinia'
import { showConfirmDialog } from 'vant'
import ConfirmDialog from './dialog/ConfirmDialog.vue'
import FromBottomDialog from './dialog/FromBottomDialog.vue'
import Loading from './Loading.vue'
import Search from './Search.vue'
import bus, { EVENT_KEY } from '@/utils/bus'
export default {
  name: 'Comment',
  components: {
    ConfirmDialog,
    FromBottomDialog,
    Loading,
    Search
  },
  props: {
    modelValue: {
      type: Boolean,
      default() {
        return false
      }
    },
    videoId: {
      type: String,
      default: null
    },
    pageId: {
      type: String,
      default: 'home-index'
    },
    height: {
      type: String,
      default: 'calc(var(--vh, 1vh) * 70)'
    },
    projectId: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState(useBaseStore, ['friends'])
  },
  watch: {
    modelValue(newValue) {
      if (newValue) {
        this.getData()
      } else {
        this.comments = []
      }
    }
  },
  data() {
    return {
      comment: '',
      test: '',
      comments: [],
      options: [
        { id: 1, name: 'Trả lời riêng' },
        { id: 2, name: 'Sao chép' },
        { id: 3, name: 'Tìm kiếm' },
        { id: 4, name: 'báo cáo' }
      ],
      selectRow: new CommentModel(),
      selectParentRow: new CommentModel(),
      showPrivateChat: false,
      isInput: false,
      isCall: false,
      loadChildren: false,
      loadChildrenItemCId: '',
      userData: new UserDataModel(),
      isLogin: false,
      unsubscribe: null as (() => void) | null, // Tham chiếu đến hàm unsubscribe
      unsubscribeRepliesList: [] as (() => void)[] // Danh sách unsubscribe cho các replies
    }
  },
  mounted() {
    this.authen()
  },
  methods: {
    _no,
    _time,
    _formatNumber,
    _checkImgUrl,
    formatTimestamp,
    authen() {
      const userData: UserDataModel | null = getUserData()
      if (userData) {
        this.isLogin = true
        this.userData = userData
      } else {
        this.isLogin = false
      }
    },
    handleBlur() {
      // Dùng để hủy trả lời comment đã chọn khi click ra ngoài input mà không nhập gì
      if (this.selectRow?.id && this.comment?.trim()?.length < 1) {
        this.selectRow = new CommentModel()
      }
      if (this.selectParentRow?.id && this.comment?.trim()?.length < 1) {
        this.selectParentRow = new CommentModel()
      }
    },
    async getData() {
      if (this.projectId) {
        const commentsRef = FirebaseService.getChildCollectionRef(
          COLLECTION_NAMES.PROJECT,
          this.projectId,
          COLLECTION_NAMES.COMMENT
        )
        const commentsQuery = query(commentsRef, orderBy('create_time', 'asc'))
        this.unsubscribe = onSnapshot(commentsQuery, async (snapshot) => {
          const comments = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          })) as CommentModel[]
          if (JSON.stringify(comments) !== JSON.stringify(this.comments)) {
            const commentsWithReplies = await Promise.all(
              comments.map(async (comment: CommentModel) => {
                const repliesRef = FirebaseService.getGrandChildCollectionRef(
                  COLLECTION_NAMES.PROJECT,
                  this.projectId,
                  COLLECTION_NAMES.COMMENT,
                  comment.id,
                  COLLECTION_NAMES.REPLIES
                )
                const repliesQuery = query(repliesRef, orderBy('create_time', 'asc'))
                // Tạo unsubscribe cho replies và thêm vào danh sách
                const unsubscribeReplies = onSnapshot(repliesQuery, (repliesSnapshot) => {
                  const replies = repliesSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                  })) as CommentModel[]
                  comment.replies = replies.map((reply: CommentModel) => {
                    reply.user_digged = (reply?.likes || []).includes(this.userData?.loginName)
                    reply.digg_count = reply?.likes?.length || 0

                    reply.user_buried = (reply?.dislikes || []).includes(this.userData?.loginName)
                    reply.bury_count = reply?.dislikes?.length || 0
                    return reply
                  })
                  comment.user_digged = (comment?.likes || []).includes(this.userData?.loginName)
                  comment.digg_count = comment?.likes?.length || 0
                  comment.user_buried = (comment?.dislikes || []).includes(this.userData?.loginName)
                  comment.bury_count = comment?.dislikes?.length || 0
                  this.comments = this.comments.map((c) => (c.id === comment.id ? comment : c))
                })
                // Thêm unsubscribe cho replies vào danh sách
                this.unsubscribeRepliesList.push(unsubscribeReplies)
                return comment
              })
            )
            this.comments = commentsWithReplies
          }
        })
      }
    },
    async handShowChildren(item: CommentModel) {
      this.loadChildrenItemCId = item.id
      this.loadChildren = true
      await _sleep(500)
      this.loadChildren = false
      if (item.showChildren) {
        //item.replies = item.replies.concat(sampleSize(item.replies, 10))
      } else {
        //item.replies = sampleSize(item.replies, 3)
        item.showChildren = true
      }
    },
    confirmLogin() {
      showConfirmDialog({
        title: 'Thông báo',
        message: 'Bạn vui lòng đăng nhập để thực hiện chức năng này.',
        cancelButtonText: 'Hủy',
        confirmButtonText: 'Đăng nhập'
      })
        .then(() => {
          this.$router.push('/login')
        })
        .catch(() => {
          return
        })
    },
    handleEnter() {
      this.send()
    },
    async send() {
      if (!this.isLogin) {
        this.confirmLogin()
      }
      if (this.comment.trim()) {
        const data: CommentModel = {
          content: this.comment,
          avatar:
            this.userData?.avatarUrl ||
            new URL('../assets/img/icon/avatar/4.png', import.meta.url).href,
          aweme_id: '',
          create_time: Timestamp.fromDate(new Date()),
          digg_count: 0,
          last_modify_ts: Timestamp.fromDate(new Date()),
          nickname: this.userData?.loginName,
          user_id: this.userData?.userId,
          replay: this.selectRow.nickname
        }
        if (this.selectRow.id) {
          await FirebaseService.addGrandChildDocument(
            COLLECTION_NAMES.PROJECT,
            this.projectId,
            COLLECTION_NAMES.COMMENT,
            this.selectParentRow?.id ? this.selectParentRow?.id : this.selectRow.id,
            COLLECTION_NAMES.REPLIES,
            data
          )
        } else {
          await FirebaseService.addChildDocument(
            COLLECTION_NAMES.PROJECT,
            this.projectId,
            COLLECTION_NAMES.COMMENT,
            data
          )
        }
        // Cập nhật số lượng comment
        await FirebaseService.updateDocument(COLLECTION_NAMES.PROJECT, this.projectId, {
          'statistics.comment_count': increment(1)
        })
        bus.emit(EVENT_KEY.INCREMENT_COMMENT, this.projectId)
        this.comment = ''
        this.isCall = false
        this.selectRow = new CommentModel()
        this.selectParentRow = new CommentModel()
      }
    },
    async reply(comment: CommentModel, parentComment: CommentModel | null = null) {
      // Nếu đang reply mà ấn lại thì không chọn nữa.
      if (this.selectRow?.id === comment?.id) {
        this.selectRow = new CommentModel()
        if (this.$refs.autoInput) {
          ;(this.$refs.autoInput as any).blur()
        }
      } else {
        if (this.$refs.autoInput) {
          ;(this.$refs.autoInput as any).focus()
        }
        this.selectParentRow = parentComment
        this.selectRow = comment
      }
    },
    cancel() {
      this.$emit('update:modelValue', false)
      this.$emit('close')
    },
    toggleCall(item) {
      item.select = !item.select
      let name = item.name
      if (this.comment.includes('@' + name)) {
        this.comment = this.comment.replace(`@${name} `, '')
      } else {
        this.comment += `@${name} `
      }
    },
    async loved(comment: CommentModel, isDislike = false) {
      if (!this.isLogin) {
        this.confirmLogin()
        return
      }
      const field = isDislike ? 'dislikes' : 'likes'
      const action = isDislike
        ? comment.user_buried
          ? arrayRemove
          : arrayUnion
        : comment.user_digged
          ? arrayRemove
          : arrayUnion
      await FirebaseService.updateChildDocument(
        COLLECTION_NAMES.PROJECT,
        this.projectId,
        COLLECTION_NAMES.COMMENT,
        comment.id,
        { [field]: action(this.userData?.loginName) }
      )
      this.updateLovedState(comment, isDislike)
    },
    async lovedReply(comment: CommentModel, parentComment: CommentModel, isDislike = false) {
      if (!this.isLogin) {
        this.confirmLogin()
        return
      }
      const field = isDislike ? 'dislikes' : 'likes'
      const action = isDislike
        ? comment.user_buried
          ? arrayRemove
          : arrayUnion
        : comment.user_digged
          ? arrayRemove
          : arrayUnion
      await FirebaseService.updateGrandChildDocument(
        COLLECTION_NAMES.PROJECT,
        this.projectId,
        COLLECTION_NAMES.COMMENT,
        parentComment.id,
        COLLECTION_NAMES.REPLIES,
        comment.id,
        { [field]: action(this.userData?.loginName) }
      )
      this.updateLovedState(comment, isDislike)
    },
    updateLovedState(comment: CommentModel, isDislike = false) {
      const countField = isDislike ? 'bury_count' : 'digg_count'
      const userField = isDislike ? 'user_buried' : 'user_digged'
      if (!comment[countField]) comment[countField] = 0
      if (comment[userField]) {
        comment[countField]--
      } else {
        comment[countField]++
      }
      comment[userField] = !comment[userField]
    },
    showOptions(row) {
      _showSelectDialog(this.options, (e) => {
        if (e.id === 1) {
          this.selectRow = row
          this.showPrivateChat = true
        }
      })
    },
    beforeDestroy() {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      // Hủy bỏ tất cả các subscriptions cho replies
      this.unsubscribeRepliesList.forEach((unsubscribeReplies) => {
        if (unsubscribeReplies) {
          unsubscribeReplies()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/less/index';

.title {
  box-sizing: border-box;
  width: 100%;
  height: 40rem;
  padding: 0 15rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10rem 10rem 0 0;

  .num {
    width: 100%;
    position: absolute;
    font-size: 12rem;
    font-weight: bold;
    text-align: center;
  }

  .right {
    display: flex;
    gap: 12rem;
    position: relative;
    z-index: 9;

    svg {
      color: #000;
      background: rgb(242, 242, 242);
      padding: 4rem;
      font-size: 16rem;
      border-radius: 50%;
    }
  }
}

.gray {
  color: var(--second-text-color);
}

.comment {
  color: #000;
  width: 100%;
  background: #fff;
  z-index: 5;

  .wrapper {
    width: 100%;
    position: relative;
    padding-bottom: 60rem;
  }

  .items {
    width: 100%;

    .item {
      width: 100%;
      margin-bottom: 15rem;

      .main {
        width: 100%;
        padding: 5rem 0;
        display: flex;

        &:active {
          background: #53535321;
        }

        .head-image {
          margin-left: 15rem;
          margin-right: 10rem;
          width: 37rem;
          height: 37rem;
          border-radius: 50%;
        }
      }

      .replies {
        padding-left: 55rem;

        .reply {
          padding: 5rem 0 5rem 5rem;
          display: flex;

          &:active {
            background: #53535321;
          }

          .head-image {
            margin-right: 10rem;
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
          }
        }

        .more {
          font-size: 13rem;
          margin: 5rem;
          display: flex;
          align-items: center;
          color: gray;

          .gang {
            background: #d5d5d5;
            width: 20rem;
            margin-right: 10rem;
            height: 1px;
          }

          span {
            margin-right: 5rem;
          }

          svg {
            font-size: 10rem;
          }
        }
      }

      .content {
        width: 100%;
        display: flex;
        font-size: 14rem;

        .comment-container {
          flex: 1;
          margin-right: 20rem;

          .name {
            color: var(--second-text-color);
            margin-bottom: 5rem;
            display: flex;
            align-items: center;

            .reply-user {
              margin-left: 5rem;
              width: 0;
              height: 0;
              border: 5rem solid transparent;
              border-left: 6rem solid gray;
            }
          }

          .detail {
            margin-bottom: 5rem;
          }

          .time-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13rem;

            .left {
              display: flex;

              .time {
                color: #c4c3c3;
                margin-right: 10rem;
              }

              .reply-text {
                color: var(--second-text-color);
              }
            }

            .love {
              color: gray;
              display: flex;
              align-items: center;

              &.loved {
                color: rgb(231, 58, 87);
              }

              .love-image {
                font-size: 17rem;
                margin-right: 4rem;
              }

              span {
                word-break: keep-all;
              }
            }
          }
        }
      }
    }
  }

  @normal-bg-color: rgb(35, 38, 47);
  @chat-bg-color: rgb(105, 143, 244);

  .input-toolbar {
    border-radius: 10rem 10rem 0 0;
    background: white;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 3;

    @space-width: 18rem;
    @icon-width: 48rem;

    .call-friend {
      padding-top: 30rem;
      overflow-x: scroll;
      display: flex;
      padding-right: @space-width;

      .friend {
        width: @icon-width;
        position: relative;
        margin-left: @space-width;
        margin-bottom: @space-width;
        font-size: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .avatar {
          width: @icon-width;
          height: @icon-width;
          border-radius: 50%;
        }

        span {
          margin-top: 5rem;
          text-align: center;
          width: @icon-width;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .checked {
          position: absolute;
          top: @icon-width - 1.5;
          right: -2px;
          width: 20rem;
          height: 20rem;
          border-radius: 50%;
        }
      }
    }

    .toolbar {
      @icon-width: 25rem;
      display: flex;
      align-items: center;
      padding: 10rem 15rem;
      border-top: 1px solid #e2e1e1;

      .input-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 5rem 10rem;
        background: #eee;
        border-radius: 20rem;

        .right {
          display: flex;
          align-items: center;
        }

        .auto-input {
          width: calc(100% - 160rem);
        }
        input {
          width: 100%;
          border: none;
          background: none;
        }
      }

      img {
        width: @icon-width;
        height: @icon-width;
        border-radius: 50%;
        margin-left: 15rem;
      }
    }
  }
}

.comment-enter-active,
.comment-leave-active {
  transition: all 0.15s ease;
}

.comment-enter-from,
.comment-leave-to {
  transform: translateY(60vh);
}

.input-control {
  width: 100%;
  border: none;
  background: none;
}
</style>
