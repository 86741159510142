<script setup lang="ts">import { ref as _ref } from 'vue';

import FirebaseService from '@/api/firebase'
import { COLLECTION_NAMES } from '@/commons/constants'
import { UserDataModel } from '@/models/user-data.model'
import { _formatNumber, cloneDeep, getUserData } from '@/utils'
import bus, { EVENT_KEY } from '@/utils/bus'
import { useClick } from '@/utils/hooks/useClick'
import { Icon } from '@iconify/vue'
import { arrayRemove, arrayUnion, increment } from 'firebase/firestore'
import { showConfirmDialog, showFailToast } from 'vant'
import { computed, inject, onMounted, ref } from 'vue'
import BaseMusic from '../BaseMusic.vue'
import { useBaseStore } from '@/store/pinia'

const props = defineProps({
  isMy: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  item: {
    type: Object,
    default: () => {
      return {}
    }
  },
  positionBar: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const userData = ref<UserDataModel | null>(new UserDataModel())
const isLogin = ref(false)
// const item = inject<any>('item')
const position = inject<any>('position')
let showMutedNotice = _ref(window.showMutedNotice)
// const nav = useNav()
const emit = defineEmits(['update:item', 'goUserInfo', 'showComments', 'showShare', 'goMusic'])
const baseStore = useBaseStore()
const follows = computed(() => baseStore['getFollowers'])
const isAttention = computed(() => {
  return (
    (follows.value || []).filter((t) => t?.userIdFollow == props.item?.created_user?.userId)
      .length > 0
  )
})
function _updateItem(props, key, val) {
  const old = cloneDeep(props.item)
  old[key] = val
  emit('update:item', old)
  bus.emit(EVENT_KEY.UPDATE_ITEM, { position: position.value, item: old })
}

function authen() {
  const data: UserDataModel | null = getUserData()
  if (data) {
    isLogin.value = true
    userData.value = data
  } else {
    isLogin.value = false
  }
}

onMounted(() => {
  authen()
  bus.on(EVENT_KEY.HIDE_MUTED_NOTICE, () => {
    showMutedNotice.value = false
  })
})

function confirmLogin() {
  showConfirmDialog({
    title: 'Thông báo',
    message: 'Bạn vui lòng đăng nhập để thực hiện chức năng này.',
    cancelButtonText: 'Hủy',
    confirmButtonText: 'Đăng nhập'
  })
    .then(() => {
      // nav('/login')
      bus.emit(EVENT_KEY.NAV, {
        path: '/login'
      })
    })
    .catch(() => {
      return
    })
}

async function loved() {
  if (!isLogin.value) {
    confirmLogin()
    return
  }
  const userId = userData?.value?.loginName
  const isCurrentlyLoved = (props.item?.loved_users || []).includes(userId)
  await FirebaseService.updateDocument(COLLECTION_NAMES.PROJECT, props.item.id, {
    'statistics.digg_count': increment(isCurrentlyLoved ? -1 : 1),
    loved_users: isCurrentlyLoved
      ? arrayRemove(userData.value.loginName) // Xóa userId khi bỏ thích
      : arrayUnion(userData.value.loginName) // Thêm userId khi thích
  })

  // Cập nhật trạng thái nội bộ của item
  const updatedLovedUsers = isCurrentlyLoved
    ? props.item.loved_users.filter((id) => id !== userId) // Xóa userId
    : [...(props.item.loved_users || []), userId] // Thêm userId

  // Phát sự kiện cập nhật với item mới, bao gồm loved_users đã được cập nhật
  bus.emit(EVENT_KEY.INCREMENT_LOVE, {
    id: props.item.id,
    value: isCurrentlyLoved ? -1 : 1,
    loved_users: updatedLovedUsers // Gửi thông tin loved_users đã cập nhật
  })
  _updateItem(props, 'loved_users', updatedLovedUsers)
}

function attention(e) {
  e.currentTarget.classList.add('attention')
  setTimeout(() => {
    _updateItem(props, 'isAttention', true)
  }, 1000)
}

function showComments() {
  bus.emit(EVENT_KEY.OPEN_COMMENTS, props.item.aweme_id)
}

const vClick = useClick()

const handleFollow = () => {
  if (props?.item?.created_user?.loginName) {
    // Nếu có thông tin
    // Nếu chưa follow thì follow mới, nếu follow rồi thì chuyển vào detail
    if (isAttention.value) {
      bus.emit(EVENT_KEY.NAV, {
        path: '/my-profile',
        query: { nickname: props?.item?.created_user?.loginName }
      })
    } else {
      bus.emit(EVENT_KEY.SET_FOLLOW, {
        userNameFollow: props?.item?.created_user?.loginName,
        userIdFollow: props?.item?.created_user?.userId
      })
    }
  } else {
    showFailToast('Thiếu dữ liệu tài khoản để Theo dõi')
  }
}

const checkHideViewDetail = () => {
  if (props.positionBar.uniqueId === 'video_detail') {
    return false
  } else if (props.positionBar.uniqueId === 'bat-dong-san') {
    return true
  } else if (location.pathname.includes('project/tvc')) {
    return false
  } else if (!props.item?.view_detail) {
    return false
  } else {
    return true
  }
}
</script>

<template>
  <div class="toolbar mb1r">
    <div class="avatar-ctn mb2r" @click="handleFollow()">
      <img
        class="avatar"
        :src="
          !props?.positionBar || props?.positionBar?.uniqueId === 'home'
            ? item?.author?.avatar_168x168?.url_list[0]
            : item?.logo
        "
        alt="img"
      />
      <!-- <img
        class="avatar"
        :src="item.author.avatar_168x168.url_list[0]"
        alt=""
        v-click="() => bus.emit(EVENT_KEY.GO_USERINFO)"
      /> -->
      <div v-click="attention" class="options">
        <img class="no" src="../../assets/img/icon/ok-white.png" alt="icon" v-if="isAttention" />
        <img class="no" src="../../assets/img/icon/add-light.png" alt="icon" v-if="!isAttention" />
        <img class="yes" src="../../assets/img/icon/ok-red.png" alt="icon" />
      </div>
    </div>
    <div class="love mb2r" v-click="loved">
      <div>
        <img
          src="../../assets/img/icon/love.svg"
          class="love-image"
          v-if="!(item?.loved_users || [])?.includes(userData?.loginName)"
        />
        <img
          src="../../assets/img/icon/loved.svg"
          class="love-image"
          v-if="(item?.loved_users || [])?.includes(userData?.loginName)"
        />
      </div>
      <span>{{ _formatNumber(item.statistics.digg_count) }}</span>
    </div>
    <!--    <div class="message mb2r" v-click="showComments">-->
    <div class="message mb2r" v-click="showComments">
      <Icon icon="mage:message-dots-round-fill" class="icon" style="color: white" />
      <span>{{ _formatNumber(item.statistics.comment_count) }}</span>
    </div>

    <!--TODO     -->
    <!--    <div class="message mb2r" v-click="() => _updateItem(props, 'isCollect', !item.isCollect)">-->
    <!--      <Icon-->
    <!--        v-if="item.isCollect"-->
    <!--        icon="ic:round-star"-->
    <!--        class="icon"-->
    <!--        style="color: rgb(252, 179, 3)"-->
    <!--      />-->
    <!--      <Icon v-else icon="ic:round-star" class="icon" style="color: white" />-->
    <!--      <span>{{ _formatNumber(item.statistics.comment_count) }}</span>-->
    <!--    </div>-->
    <!--    <div v-if="!props.isMy" class="share mb2r" v-click="() => bus.emit(EVENT_KEY.SHOW_SHARE)">-->
    <!--    <div v-if="!props.isMy" class="share mb2r">-->
    <!--      <img src="../../assets/img/icon/share-white-full.png" alt="" class="share-image" />-->
    <!--      <span>{{ _formatNumber(item.statistics.share_count) }}</span>-->
    <!--    </div>-->
    <!--    <div v-else class="share mb2r" v-click="() => bus.emit(EVENT_KEY.SHOW_SHARE)">-->
    <!--      <img src="../../assets/img/icon/menu-white.png" alt="" class="share-image" />-->
    <!--    </div>-->
    <!--    <BaseMusic-->
    <!--        :cover="item.music.cover"-->
    <!--        v-click="$router.push('/home/music')"-->
    <!--    /> -->
    <BaseMusic />
    <div class="button-transition" v-if="checkHideViewDetail()">
      <div
        class="frame product-in-video"
        :class="showMutedNotice && 'notice'"
        v-click="
          () =>
            bus.emit(EVENT_KEY.NAV, {
              path: '/project/tvc',
              query: { id: item.id, view: position.uniqueId }
            })
        "
      >
        <div class="d-flex middle">
          <span class="icon">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.25 0.25V2.75H0.75C0.683696 2.75 0.620107 2.72366 0.573223 2.67678C0.526339 2.62989 0.5 2.5663 0.5 2.5V1C0.5 0.734784 0.605357 0.48043 0.792893 0.292893C0.98043 0.105357 1.23478 0 1.5 0H4C4.0663 0 4.12989 0.0263393 4.17678 0.0732234C4.22366 0.120107 4.25 0.183696 4.25 0.25ZM5.25 9.75C5.25 9.8163 5.27634 9.87989 5.32322 9.92678C5.37011 9.97366 5.4337 10 5.5 10H8.5C8.5663 10 8.62989 9.97366 8.67678 9.92678C8.72366 9.87989 8.75 9.8163 8.75 9.75V7.25H5.25V9.75ZM0.5 7.5V9C0.5 9.26522 0.605357 9.51957 0.792893 9.70711C0.98043 9.89464 1.23478 10 1.5 10H4C4.0663 10 4.12989 9.97366 4.17678 9.92678C4.22366 9.87989 4.25 9.8163 4.25 9.75V7.25H0.75C0.683696 7.25 0.620107 7.27634 0.573223 7.32322C0.526339 7.37011 0.5 7.4337 0.5 7.5ZM0.5 4V6C0.5 6.0663 0.526339 6.12989 0.573223 6.17678C0.620107 6.22366 0.683696 6.25 0.75 6.25H4.25V3.75H0.75C0.683696 3.75 0.620107 3.77634 0.573223 3.82322C0.526339 3.87011 0.5 3.9337 0.5 4ZM8.5 0H5.5C5.4337 0 5.37011 0.0263393 5.32322 0.0732234C5.27634 0.120107 5.25 0.183696 5.25 0.25V2.75H8.75V0.25C8.75 0.183696 8.72366 0.120107 8.67678 0.0732234C8.62989 0.0263393 8.5663 0 8.5 0ZM13.25 3.75H9.75V6.25H13.25C13.3163 6.25 13.3799 6.22366 13.4268 6.17678C13.4737 6.12989 13.5 6.0663 13.5 6V4C13.5 3.9337 13.4737 3.87011 13.4268 3.82322C13.3799 3.77634 13.3163 3.75 13.25 3.75ZM5.25 6.25H8.75V3.75H5.25V6.25ZM12.5 0H10C9.9337 0 9.87011 0.0263393 9.82322 0.0732234C9.77634 0.120107 9.75 0.183696 9.75 0.25V2.75H13.25C13.3163 2.75 13.3799 2.72366 13.4268 2.67678C13.4737 2.62989 13.5 2.5663 13.5 2.5V1C13.5 0.734784 13.3946 0.48043 13.2071 0.292893C13.0196 0.105357 12.7652 0 12.5 0ZM13.25 7.25H9.75V9.75C9.75 9.8163 9.77634 9.87989 9.82322 9.92678C9.87011 9.97366 9.9337 10 10 10H12.5C12.7652 10 13.0196 9.89464 13.2071 9.70711C13.3946 9.51957 13.5 9.26522 13.5 9V7.5C13.5 7.4337 13.4737 7.37011 13.4268 7.32322C13.3799 7.27634 13.3163 7.25 13.25 7.25Z"
                fill="#AB4ABA"
              />
            </svg>
          </span>
          <span class="text" :style="{ display: showMutedNotice ? 'block' : 'none' }">
            Xem chi tiết
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.toolbar {
  //width: 40px;
  position: absolute;
  bottom: 150px;
  right: 10rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  .avatar-ctn {
    position: relative;

    @w: 45rem;

    .avatar {
      width: @w;
      height: @w;
      border: 3rem solid white;
      border-radius: 50%;
    }

    .options {
      position: absolute;
      border-radius: 50%;
      margin: auto;
      left: 0;
      right: 0;
      bottom: -5px;
      background: red;
      //background: black;
      width: 18rem;
      height: 18rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 1s;

      img {
        position: absolute;
        width: 14rem;
        height: 14rem;
        transition: all 1s;
      }

      .yes {
        opacity: 0;
        transform: rotate(-180deg);
      }

      &.attention {
        background: white;

        .no {
          opacity: 0;
          transform: rotate(180deg);
        }

        .yes {
          opacity: 1;
          transform: rotate(0deg);
        }
      }
    }
  }

  .love,
  .message,
  .share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @width: 35rem;

    img {
      width: @width;
      height: @width;
    }

    span {
      font-size: 12rem;
    }
  }

  .icon {
    font-size: 40rem;
  }

  .loved {
    background: red;
  }
}
.toolbar {
  .button-transition {
    width: 45rem;
    position: relative;
    .product-in-video {
      text-wrap: nowrap;
      width: 45rem;
      height: 45rem;
      padding-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      position: absolute;
      z-index: 1;
      right: 0;
      &.notice {
        background: white;
        border-radius: 50rem;
        width: 100rem;
        margin-top: 10px;
      }
      .text {
        color: #000;
        font-size: 12px;
      }
      .icon {
        display: flex;
      }
    }
  }
}
.love-image {
  width: 43rem !important;
  height: 43rem !important;
}
.message svg {
  font-size: 46rem !important;
}
</style>
