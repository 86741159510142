<script setup lang="ts">
import { inject, reactive } from 'vue'
import { useClick } from '@/utils/hooks/useClick'
import bus, { EVENT_KEY } from '../../utils/bus'
import { getUserData } from '@/utils'
import { UserDataModel } from '@/models/user-data.model'
const props = defineProps({
  isMy: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  isLive: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  position: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
let pathname = ''
const vClick = useClick()
const item = inject<any>('item')
const state = reactive({
  isAttention: false,
  test: [1, 2]
})
const checkHideViewDetail = () => {
  if (props.position.uniqueId === 'video_detail') {
    return false
  } else if (props.position.uniqueId === 'bat-dong-san') {
    return false
  } else if (!item?.value?.view_detail) {
    return false
  } else {
    return true
  }
}
if (item?.author?.nickname) {
  bus.emit(EVENT_KEY.NAV, {
    path: '/my-profile',
    query: { nickname: item?.author?.nickname }
  })
}
const handleFollow = () => {
  console.log(item?.value?.created_user?.loginName)
  bus.emit(EVENT_KEY.NAV, {
    path: '/my-profile',
    query: { nickname: item?.value?.created_user?.loginName }
  })
}
</script>
<template>
  <div class="item-desc mb1r" style="z-index: 9">
    <div class="content" v-if="!props.isMy">
      <!--      <div class="location-wrapper" v-if="item.city || item.address">-->
      <!--        <div class="location d-flex middle">-->
      <!--          <span class="icon">-->
      <!--            <svg-->
      <!--              width="12"-->
      <!--              height="14"-->
      <!--              viewBox="0 0 12 14"-->
      <!--              fill="none"-->
      <!--              xmlns="http://www.w3.org/2000/svg"-->
      <!--            >-->
      <!--              <path-->
      <!--                d="M6 0C4.54182 0.00165421 3.14383 0.581648 2.11274 1.61274C1.08165 2.64383 0.501654 4.04182 0.5 5.5C0.5 10.2063 5.5 13.7606 5.71313 13.9094C5.7972 13.9683 5.89735 13.9999 6 13.9999C6.10265 13.9999 6.2028 13.9683 6.28687 13.9094C6.5 13.7606 11.5 10.2063 11.5 5.5C11.4983 4.04182 10.9184 2.64383 9.88726 1.61274C8.85617 0.581648 7.45818 0.00165421 6 0ZM6 3.5C6.39556 3.5 6.78224 3.6173 7.11114 3.83706C7.44004 4.05682 7.69638 4.36918 7.84776 4.73463C7.99913 5.10009 8.03874 5.50222 7.96157 5.89018C7.8844 6.27814 7.69392 6.63451 7.41421 6.91421C7.13451 7.19392 6.77814 7.3844 6.39018 7.46157C6.00222 7.53874 5.60009 7.49913 5.23463 7.34776C4.86918 7.19638 4.55682 6.94004 4.33706 6.61114C4.1173 6.28224 4 5.89556 4 5.5C4 4.96957 4.21071 4.46086 4.58579 4.08579C4.96086 3.71071 5.46957 3.5 6 3.5Z"-->
      <!--                fill="#279643"-->
      <!--              />-->
      <!--            </svg>-->
      <!--          </span>-->
      <!--          <span>{{ item.city }}</span>-->
      <!--          <template v-if="item.address">-->
      <!--            <div class="gang"></div>-->
      <!--          </template>-->
      <!--          <span>{{ item.address }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="frame product-in-video" v-if="checkHideViewDetail()">-->
      <!--        <div-->
      <!--          class="d-flex middle detail-transition"-->
      <!--          v-click="-->
      <!--            () =>-->
      <!--              bus.emit(EVENT_KEY.NAV, {-->
      <!--                path: '/project/tvc',-->
      <!--                query: { id: item.id, view: props.position.uniqueId }-->
      <!--              })-->
      <!--          "-->
      <!--        >-->
      <!--          <span class="icon">-->
      <!--            <svg-->
      <!--              width="14"-->
      <!--              height="10"-->
      <!--              viewBox="0 0 14 10"-->
      <!--              fill="none"-->
      <!--              xmlns="http://www.w3.org/2000/svg"-->
      <!--            >-->
      <!--              <path-->
      <!--                d="M4.25 0.25V2.75H0.75C0.683696 2.75 0.620107 2.72366 0.573223 2.67678C0.526339 2.62989 0.5 2.5663 0.5 2.5V1C0.5 0.734784 0.605357 0.48043 0.792893 0.292893C0.98043 0.105357 1.23478 0 1.5 0H4C4.0663 0 4.12989 0.0263393 4.17678 0.0732234C4.22366 0.120107 4.25 0.183696 4.25 0.25ZM5.25 9.75C5.25 9.8163 5.27634 9.87989 5.32322 9.92678C5.37011 9.97366 5.4337 10 5.5 10H8.5C8.5663 10 8.62989 9.97366 8.67678 9.92678C8.72366 9.87989 8.75 9.8163 8.75 9.75V7.25H5.25V9.75ZM0.5 7.5V9C0.5 9.26522 0.605357 9.51957 0.792893 9.70711C0.98043 9.89464 1.23478 10 1.5 10H4C4.0663 10 4.12989 9.97366 4.17678 9.92678C4.22366 9.87989 4.25 9.8163 4.25 9.75V7.25H0.75C0.683696 7.25 0.620107 7.27634 0.573223 7.32322C0.526339 7.37011 0.5 7.4337 0.5 7.5ZM0.5 4V6C0.5 6.0663 0.526339 6.12989 0.573223 6.17678C0.620107 6.22366 0.683696 6.25 0.75 6.25H4.25V3.75H0.75C0.683696 3.75 0.620107 3.77634 0.573223 3.82322C0.526339 3.87011 0.5 3.9337 0.5 4ZM8.5 0H5.5C5.4337 0 5.37011 0.0263393 5.32322 0.0732234C5.27634 0.120107 5.25 0.183696 5.25 0.25V2.75H8.75V0.25C8.75 0.183696 8.72366 0.120107 8.67678 0.0732234C8.62989 0.0263393 8.5663 0 8.5 0ZM13.25 3.75H9.75V6.25H13.25C13.3163 6.25 13.3799 6.22366 13.4268 6.17678C13.4737 6.12989 13.5 6.0663 13.5 6V4C13.5 3.9337 13.4737 3.87011 13.4268 3.82322C13.3799 3.77634 13.3163 3.75 13.25 3.75ZM5.25 6.25H8.75V3.75H5.25V6.25ZM12.5 0H10C9.9337 0 9.87011 0.0263393 9.82322 0.0732234C9.77634 0.120107 9.75 0.183696 9.75 0.25V2.75H13.25C13.3163 2.75 13.3799 2.72366 13.4268 2.67678C13.4737 2.62989 13.5 2.5663 13.5 2.5V1C13.5 0.734784 13.3946 0.48043 13.2071 0.292893C13.0196 0.105357 12.7652 0 12.5 0ZM13.25 7.25H9.75V9.75C9.75 9.8163 9.77634 9.87989 9.82322 9.92678C9.87011 9.97366 9.9337 10 10 10H12.5C12.7652 10 13.0196 9.89464 13.2071 9.70711C13.3946 9.51957 13.5 9.26522 13.5 9V7.5C13.5 7.4337 13.4737 7.37011 13.4268 7.32322C13.3799 7.27634 13.3163 7.25 13.25 7.25Z"-->
      <!--                fill="#AB4ABA"-->
      <!--              />-->
      <!--            </svg>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="author">
        <div class="d-flex middle">
          <span class="name" v-if="item.author" @click="handleFollow()"
            >{{
              props.position.uniqueId === 'home' ? item.author.nickname : item?.author?.noble_name
            }}
          </span>
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2001_4784)">
              <path
                d="M6.89276 11.9281C10.1669 11.9281 12.8212 9.27386 12.8212 5.99969C12.8212 2.72552 10.1669 0.0712891 6.89276 0.0712891C3.61859 0.0712891 0.964355 2.72552 0.964355 5.99969C0.964355 9.27386 3.61859 11.9281 6.89276 11.9281Z"
                fill="#20D5EC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.134 3.99342C10.4234 4.28283 10.4234 4.75201 10.134 5.04141L6.79926 8.37614C6.50985 8.66554 6.04067 8.66554 5.75126 8.37614L3.65162 6.2765C3.36222 5.98709 3.36222 5.51791 3.65162 5.2285C3.94103 4.9391 4.41021 4.9391 4.69961 5.2285L6.27526 6.80415L9.08599 3.99342C9.37539 3.70402 9.84458 3.70402 10.134 3.99342Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2001_4784">
                <rect
                  width="11.8568"
                  height="11.8568"
                  fill="white"
                  transform="translate(0.964355 0.0712891)"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            width="3"
            height="3"
            viewBox="0 0 3 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.78516 2.51562C1.25195 2.51562 0.853516 2.11133 0.853516 1.58984C0.853516 1.06836 1.25195 0.664062 1.78516 0.664062C2.32422 0.664062 2.7168 1.06836 2.7168 1.58984C2.7168 2.11133 2.32422 2.51562 1.78516 2.51562Z"
              fill="#FFFFFF"
            />
          </svg>
          <span class="by">Tác giả</span>
        </div>
      </div>

      <!--      <div class="price">-->
      <!--        <div class="d-flex middle">-->
      <!--          257,000,000 đ-->
      <!--          <svg-->
      <!--            width="16"-->
      <!--            height="16"-->
      <!--            viewBox="0 0 16 16"-->
      <!--            fill="none"-->
      <!--            xmlns="http://www.w3.org/2000/svg"-->
      <!--          >-->
      <!--            <path-->
      <!--              d="M13.0306 9.53063L8.5306 14.0306C8.46092 14.1005 8.37813 14.156 8.28696 14.1939C8.1958 14.2317 8.09806 14.2512 7.99935 14.2512C7.90064 14.2512 7.8029 14.2317 7.71173 14.1939C7.62057 14.156 7.53778 14.1005 7.4681 14.0306L2.9681 9.53063C2.8272 9.38973 2.74805 9.19863 2.74805 8.99938C2.74805 8.80012 2.8272 8.60902 2.9681 8.46813C3.10899 8.32723 3.30009 8.24807 3.49935 8.24807C3.69861 8.24807 3.8897 8.32723 4.0306 8.46813L7.24997 11.6875V2.5C7.24997 2.30109 7.32899 2.11032 7.46964 1.96967C7.61029 1.82902 7.80106 1.75 7.99997 1.75C8.19889 1.75 8.38965 1.82902 8.5303 1.96967C8.67095 2.11032 8.74997 2.30109 8.74997 2.5V11.6875L11.9693 8.4675C12.1102 8.3266 12.3013 8.24745 12.5006 8.24745C12.6999 8.24745 12.891 8.3266 13.0318 8.4675C13.1727 8.6084 13.2519 8.79949 13.2519 8.99875C13.2519 9.19801 13.1727 9.3891 13.0318 9.53L13.0306 9.53063Z"-->
      <!--              fill="#46A758"-->
      <!--            />-->
      <!--          </svg>-->
      <!--          <span class="regular-price">-->
      <!--            <del>100,000,000 đ</del>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        <div class="price-per">Est. 40,000,000 đ/m2</div>-->
      <!--      </div>-->
      <div class="product-info">
        <!--        <div class="name">Biệt thự A1 -D1</div>-->
        <div
          v-if="props.position.uniqueId == 'bat-dong-san'"
          class="place"
          style="font-weight: 700; font-size: 20px"
        >
          {{ item?.video?.title }}
        </div>
        <div class="place" :class="props.position.uniqueId == 'bat-dong-san' ? 'fontSize20' : ''">
          {{ props.position.uniqueId == 'bat-dong-san' ? item?.video?.trailer_desc : item.desc }}
        </div>
        <!--        <div class="tag" v-if="item.hashtag && props.position.uniqueId !== 'bat-dong-san'">-->
        <!--          {{ item.hashtag }}-->
        <!--        </div>-->
        <div class="frame" v-if="props.position.uniqueId == 'bat-dong-san'">
          <div
            class="detail-transition"
            v-click="
              () =>
                bus.emit(EVENT_KEY.NAV, {
                  path: '/project/tvc',
                  query: { id: item.id, view: props.position.uniqueId }
                })
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="back iconify iconify--icon-park-outline"
              width="1em"
              height="1em"
              viewBox="0 0 48 48"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M31 36L19 24l12-12"
              ></path>
            </svg>
            Xem chi tiết
          </div>
        </div>
      </div>
      <!-- <div class="live" v-if="props.isLive">直播中</div> -->
      <!-- <div class="name mb1r f18 fb" @click.stop="$emit('goUserInfo')">
        @{{ item.author.nickname }}
      </div> -->
      <!-- <div class="description">
        {{ item.desc }}
      </div> -->
      <!--      <div class="music" @click.stop="bus.emit('nav','/home/music')">-->
      <!--        <img src="../../assets/img/icon/music.svg" alt="" class="music-image">-->
      <!--        <span>{{ item.music.title }}</span>-->
      <!--      </div>-->
    </div>
    <div v-else class="comment-status">
      <div class="comment">
        <div class="type-comment">
          <img src="../../assets/img/icon/head-image.jpeg" alt="" class="avatar" />
          <div class="right">
            <p>
              <span class="name">zzzzz</span>
              <span class="time">2020-01-20</span>
            </p>
            <p class="text">ss</p>
          </div>
        </div>
        <transition-group name="comment-status" tag="div" class="loveds">
          <div class="type-loved" :key="i" v-for="i in state.test">
            <img src="../../assets/img/icon/head-image.jpeg" alt="" class="avatar" />
            <img src="../../assets/img/icon/love.svg" alt="" class="loved" />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.item-desc {
  position: absolute;
  bottom: 0;
  width: 70%;

  .content {
    color: #fff;
    text-align: left;

    .location-wrapper {
      display: flex;
    }

    .live {
      border-radius: 3rem;
      margin-bottom: 10rem;
      padding: 3rem 6rem;
      font-size: 11rem;
      display: inline-flex;
      background: var(--primary-btn-color);
      color: white;
    }

    .music {
      position: relative;
      display: flex;
      align-items: center;

      .music-image {
        width: 20px;
        height: 20px;
      }
    }
  }

  .comment-status {
    display: flex;
    align-items: center;

    .comment {
      .type-comment {
        display: flex;
        background: rgb(130, 21, 44);
        border-radius: 50px;
        padding: 3px;
        margin-bottom: 20px;

        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }

        .right {
          margin: 0 10px;
          color: var(--second-text-color);

          .name {
            margin-right: 10px;
          }

          .text {
            color: white;
          }
        }
      }

      .loveds {
      }

      .type-loved {
        width: 40px;
        height: 40px;
        position: relative;
        margin-bottom: 20px;
        animation: test 1s;
        animation-delay: 0.5s;

        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }

        .loved {
          position: absolute;
          bottom: 0;
          left: 20px;
          width: 10px;
          height: 10px;
          background: red;
          padding: 3px;
          border-radius: 50%;
          border: 2px solid white;
        }
      }

      @keyframes test {
        from {
          display: block;
          transform: translate3d(0, 0, 0);
        }
        to {
          display: none;
          transform: translate3d(0, -60px, 0);
        }
      }
    }
  }
}
</style>
