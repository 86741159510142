import type { Timestamp } from 'firebase/firestore'

export class CommentModel {
  id? = ''
  aweme_id = ''
  content = ''
  last_modify_ts: Timestamp = null // Thời gian chỉnh sửa comment
  create_time: Timestamp = null

  // Thông tin người dùng comement
  avatar: string = ''
  nickname = ''
  user_id = ''

  ip_location? = ''
  user_digged? = false // Tài khoản có like comment không
  digg_count? = 0 // Số lượng like comment này
  replay? = '' // Thông tin trả lời comment người dùng nào
  user_buried?: any = false // Người dùng có Dislike không
  bury_count? = 0 // Số lượng Dislike comment
  showChildren? = false

  replies?: CommentModel[] = [] // Danh sách các comment trả lời

  likes?: string[] = [] // Lưu danh sách tài khoản like comment
  dislikes?: string[] = []
}

export class testmodel {}
