<template>
  <div class="d-flex center topbar middle custom-topbar" style="padding: 0">
    <!-- <span class="live">
      <svg
        width="25"
        height="27"
        viewBox="0 0 25 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_109_25645)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.20711 1.29289C8.81658 0.902369 8.18342 0.902369 7.79289 1.29289C7.40237 1.68342 7.40237 2.31658 7.79289 2.70711L10.0858 5H4.5C2.567 5 1 6.567 1 8.5C1 9.05229 1.44772 9.5 2 9.5C2.55228 9.5 3 9.05229 3 8.5C3 7.67157 3.67157 7 4.5 7H12.5H20.5C21.3284 7 22 7.67157 22 8.5C22 9.05229 22.4477 9.5 23 9.5C23.5523 9.5 24 9.05229 24 8.5C24 6.567 22.433 5 20.5 5H14.9142L17.2071 2.70711C17.5976 2.31658 17.5976 1.68342 17.2071 1.29289C16.8166 0.902369 16.1834 0.902369 15.7929 1.29289L12.5 4.58579L9.20711 1.29289ZM3 21C3 20.4477 2.55228 20 2 20C1.44772 20 1 20.4477 1 21C1 23.2091 2.79086 25 5 25H20C22.2091 25 24 23.2091 24 21C24 20.4477 23.5523 20 23 20C22.4477 20 22 20.4477 22 21C22 22.1046 21.1046 23 20 23H5C3.89543 23 3 22.1046 3 21ZM12.4231 11.6154C12.2107 11.1056 11.6252 10.8645 11.1154 11.0769C10.6056 11.2893 10.3645 11.8748 10.5769 12.3846L13.0769 18.3846C13.2322 18.7573 13.5963 19 14 19C14.4037 19 14.7678 18.7573 14.9231 18.3846L17.4231 12.3846C17.6355 11.8748 17.3944 11.2893 16.8846 11.0769C16.3748 10.8645 15.7893 11.1056 15.5769 11.6154L14 15.4L12.4231 11.6154ZM3 12C3 11.4477 2.55228 11 2 11C1.44772 11 1 11.4477 1 12V18C1 18.5523 1.44772 19 2 19H5C5.55228 19 6 18.5523 6 18C6 17.4477 5.55228 17 5 17H3V12ZM9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12V18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18V12ZM20 11C19.4477 11 19 11.4477 19 12V15V18C19 18.5523 19.4477 19 20 19H23C23.5523 19 24 18.5523 24 18C24 17.4477 23.5523 17 23 17H21V16H23C23.5523 16 24 15.5523 24 15C24 14.4477 23.5523 14 23 14H21V13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11H20Z"
            fill="white"
            fill-opacity="0.9"
            shape-rendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_109_25645"
            x="0"
            y="0.5"
            width="25"
            height="26"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_25645" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_109_25645"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </span> -->
    <span></span>
    <nav>
      <li @click="() => onClickLeft()" :class="activeIndex == 0 ? 'activeOrg' : ''">
        Đang followed
      </li>
      <li @click="() => onClickRight()" :class="activeIndex == 1 ? 'activeOrg' : ''">Gợi ý</li>
    </nav>
    <span @click="handleSearch">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5306 20.4696L16.8365 15.7765C18.1971 14.1431 18.8755 12.048 18.7307 9.92715C18.5859 7.80629 17.629 5.82289 16.0591 4.38956C14.4892 2.95623 12.4271 2.18333 10.3019 2.23163C8.17663 2.27993 6.15181 3.14571 4.64864 4.64888C3.14547 6.15205 2.27968 8.17687 2.23138 10.3021C2.18308 12.4274 2.95599 14.4894 4.38932 16.0593C5.82265 17.6293 7.80604 18.5861 9.92691 18.7309C12.0478 18.8757 14.1428 18.1973 15.7762 16.8368L20.4693 21.5308C20.539 21.6005 20.6218 21.6558 20.7128 21.6935C20.8038 21.7312 20.9014 21.7506 21 21.7506C21.0985 21.7506 21.1961 21.7312 21.2871 21.6935C21.3782 21.6558 21.4609 21.6005 21.5306 21.5308C21.6003 21.4612 21.6556 21.3784 21.6933 21.2874C21.731 21.1963 21.7504 21.0988 21.7504 21.0002C21.7504 20.9017 21.731 20.8041 21.6933 20.713C21.6556 20.622 21.6003 20.5393 21.5306 20.4696ZM3.74997 10.5002C3.74997 9.16519 4.14585 7.86015 4.88755 6.75011C5.62925 5.64008 6.68345 4.77492 7.91686 4.26403C9.15026 3.75314 10.5075 3.61946 11.8168 3.87991C13.1262 4.14036 14.3289 4.78324 15.2729 5.72724C16.2169 6.67125 16.8598 7.87398 17.1203 9.18335C17.3807 10.4927 17.247 11.8499 16.7362 13.0833C16.2253 14.3167 15.3601 15.3709 14.2501 16.1126C13.14 16.8543 11.835 17.2502 10.5 17.2502C8.71037 17.2482 6.99463 16.5364 5.72919 15.271C4.46375 14.0056 3.75195 12.2898 3.74997 10.5002Z"
          fill="white"
        />
      </svg>
    </span>
  </div>
  <!-- <div class="search-bar-top">
     <span class="back">
       <svg
         width="12"
         height="18"
         viewBox="0 0 12 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
       >
         <path
           d="M1.1998 8.2L9.3998 0.3C9.5998 0.0999999 9.7998 0 10.0998 0C10.6998 0 11.0998 0.5 11.0998 1C11.0998 1.3 10.9998 1.5 10.7998 1.7L3.2998 9L10.7998 16.2C10.9998 16.4 11.0998 16.7 11.0998 17C11.0998 17.6 10.5998 18 10.0998 18C9.7998 18 9.5998 17.9 9.3998 17.7L1.1998 9.8C0.999804 9.6 0.799805 9.3 0.799805 9C0.799805 8.7 0.999804 8.4 1.1998 8.2Z"
           fill="#212633"
         />
       </svg>
     </span>
     <div class="field search">
       <svg
         class="icon-search"
         width="18"
         height="18"
         viewBox="0 0 18 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
       >
         <path
           fill-rule="evenodd"
           clip-rule="evenodd"
           d="M12.1958 13.8928C10.9284 14.8156 9.36777 15.36 7.68002 15.36C3.43846 15.36 0 11.9216 0 7.68002C0 3.43846 3.43846 0 7.68002 0C11.9216 0 15.36 3.43846 15.36 7.68002C15.36 9.36777 14.8156 10.9284 13.8928 12.1958L17.6485 15.9515C18.1172 16.4201 18.1172 17.1799 17.6485 17.6485C17.1799 18.1172 16.4201 18.1172 15.9515 17.6485L12.1958 13.8928ZM13.92 7.68002C13.92 11.1263 11.1263 13.92 7.68002 13.92C4.23376 13.92 1.44 11.1263 1.44 7.68002C1.44 4.23376 4.23376 1.44 7.68002 1.44C11.1263 1.44 13.92 4.23376 13.92 7.68002Z"
           fill="#202020"
         />
       </svg>
       <input type="text" placeholder="Triển khai dự án tại Sunshine..." />
     </div>
   </div> -->
</template>

<script>
export default {
  name: 'BaseSearch',
  props: {
    indexActive: {
      type: Number,
      default() {
        return 1
      }
    },
    setTopTabIndex: {
      type: Function,
      default: () => {} // Ensure a default empty function to avoid undefined issues
    }
  },

  data() {
    return {
      token: localStorage.getItem('ss_tt_authToken'),
      activeIndex: window.location.pathname.includes('home') ? 1 : 0
    }
  },
  methods: {
    $nav(path) {
      this.$router.push(path)
    },
    onClickLeft() {
      if (this.token) {
        this.$nav('/follow')
      } else {
        this.$nav('/login-follow')
      }
      this.activeIndex = 0
      this.setTopTabIndex(0)
    },
    onClickRight() {
      this.activeIndex = 1
      this.setTopTabIndex(1)
      this.$nav('/home')
    },
    openMenu() {},
    handleSearch() {
      this.$nav('/tim-kiem')
    }
  }
}
</script>

<style scoped lang="less">
/* Add your custom styles here */
.van-nav-bar {
  z-index: 99;
  background: #ffffff16;
  position: absolute;
  bottom: 68px;
  height: 36px;
  border: none;
  left: 0px;
  width: 100%;
  text-align: left;
}
.van-icon {
  color: #fff;
  font-size: 19px;
  height: auto;
}
.van-nav-bar__title {
  border-bottom: none;
  color: #fff;
  margin-left: 0;
  padding-left: 45px;
  max-width: 100%;
}
.humberger {
  width: 30px;
  height: 26px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 0;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 10px;
    }

    &:nth-child(3) {
      top: 20px;
    }
  }
}
.wrap-topbar {
  position: absolute;
  top: 12px;
  width: 100%;
  z-index: 9;
  padding: 12px;
  .logo {
    color: #fff;
  }
  .menu {
    padding: 20px 10px 15px;
    background: #018ee5d4;
    border-bottom: 2px solid #efefef6b;
  }
}

.custom-topbar {
  top: 20px;
  justify-content: space-around !important;
}
</style>
