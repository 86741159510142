export class UserDataProfileModel {
  displayName: string = ''
  email: string = ''
  phoneNumber: string = ''
  photoURL: string = ''
  providerId: string = ''
  uid: string = ''
}

export class UserDataTokenModel {
  accessToken: string = ''
  expirationTime: string = ''
  refreshToken: string = ''
}

// export class UserDataModel {
//   apiKey: string = ''
//   appName: string = ''
//   createdAt: string = ''
//   email: string = ''
//   emailVerified: boolean = false
//   isAnonymous: boolean = false
//   lastLoginAt: string = ''
//   providerData: UserDataProfileModel[] = []
//   stsTokenManager: UserDataTokenModel = new UserDataTokenModel()
// }

class Field {
  group_cd: string = ''
  field_name: string = ''
  data_type: string = ''
  columnLabel: string = ''
  columnLabelE: string = ''
  columnValue: string = ''
  columnClass: string = ''
  columnType: string = ''
  columnObject: string = ''
  columnTooltip: string = ''
  isSpecial: boolean = false
  isRequire: boolean = false
  isDisable: boolean = false
  isVisiable: boolean = false
  isEmpty: boolean = false
  columnDisplay: string = ''
  isIgnore: boolean = false
  table_name: string = ''
}

class Point {
  referralCd: string = ''
  cur_bal_point: number = 0
  last_dt: number = 0
  u_rank: number = 0
  gr_rank: number = 0
}

class Meta {
  metaId: string = ''
  metaNote: string = ''
  metaName: string = ''
  metaUrl: string = ''
  metatype: string = ''
  doc_type: string = ''
  doc_sub_type: string = ''
  doc_sub_type_name: string = ''
  noneUrl: string = ''
}
export class UserDataModel {
  nickName: string = ''
  fullName: string = ''
  phone: string = ''
  email: string = ''
  sex: string = ''
  birthday: string = ''
  res_Add: string = ''
  res_Cntry: string = ''
  trad_Add: string = ''
  email_Verified: boolean = false
  idcard_Verified: boolean = false
  agreed_St: boolean = false
  referralCd: string = ''
  core_linked: boolean = false
  residen_linked: boolean = false
  staff_linked: boolean = false
  fb_linked: boolean = false
  gg_linked: boolean = false
  invited_st: boolean = false
  sale_st: boolean = false
  loginType: number = 0
  fields: Field[] = [new Field()]
  point: Point = new Point()
  metas: Meta[] = [new Meta()]
  avatarUrl?: string = ''
  userId?: string = ''
  loginName?: string = ''
  isUserCms: boolean = false
  isUserNoble?: boolean = false
}

export class UserProfileIntro {
  nick_name: string = ''
  userId: string = ''
  avatar: string = ''
  cover_image: string = ''
  job_university: string = ''
  job_office_work: string = ''
  education: string = ''
  address: string = ''
  hometown: string = ''
}

export class UserCmsModel {
  reg_userId: number = 0
  userId: string = ''
  avatarUrl: string = ''
  loginName: string = ''
  fullName: string = ''
  isUserCms: boolean = false
  isUserNoble: boolean = false
  phone: string = ''
}
