import { request } from '@/utils/request1'

export function getDepartments() {
  return request({
    url: '/api/v1/livechat/department',
    method: 'get'
  })
}
export function getChannels() {
  return request({
    url: '/api/v1/groups.list',
    method: 'get'
  })
}
