<template>
  <div class="video-wrapper" ref="videoWrapper" :class="positionName">
    <Loading v-if="state.loading" style="position: absolute" />
    <!--    <video :src="item.video + '?v=123'"-->
    <!--    item?.codeType !== 'tvc'"-->
    <!--    <div class="frame product-in-video" v-if="checkHideViewDetail()">-->
    <!--      <div-->
    <!--        class="pointer"-->
    <!--        v-if="-->
    <!--          props.position.uniqueId === 'video_product' || props.position.uniqueId === 'bat-dong-san'-->
    <!--        "-->
    <!--      >-->
    <!--        <Icon-->
    <!--          class="back back-transition"-->
    <!--          icon="icon-park-outline:left"-->
    <!--          @click="-->
    <!--            () =>-->
    <!--              bus.emit(EVENT_KEY.NAV, {-->
    <!--                path:-->
    <!--                  props.position.uniqueId === 'bat-dong-san'-->
    <!--                    ? '/transaction'-->
    <!--                    : '/transaction/batdongsan'-->
    <!--              })-->
    <!--          "-->
    <!--        />-->
    <!--      </div>-->
    <!--      <div-->
    <!--        class="d-flex middle detail-transition"-->
    <!--        v-if="item?.view_detail"-->
    <!--        v-click="-->
    <!--          () =>-->
    <!--            bus.emit(EVENT_KEY.NAV, {-->
    <!--              path: '/project/tvc',-->
    <!--              query: { id: item.id, view: props.position.uniqueId }-->
    <!--            })-->
    <!--        "-->
    <!--      >-->
    <!--        <span class="icon">-->
    <!--          <svg-->
    <!--            width="14"-->
    <!--            height="10"-->
    <!--            viewBox="0 0 14 10"-->
    <!--            fill="none"-->
    <!--            xmlns="http://www.w3.org/2000/svg"-->
    <!--          >-->
    <!--            <path-->
    <!--              d="M4.25 0.25V2.75H0.75C0.683696 2.75 0.620107 2.72366 0.573223 2.67678C0.526339 2.62989 0.5 2.5663 0.5 2.5V1C0.5 0.734784 0.605357 0.48043 0.792893 0.292893C0.98043 0.105357 1.23478 0 1.5 0H4C4.0663 0 4.12989 0.0263393 4.17678 0.0732234C4.22366 0.120107 4.25 0.183696 4.25 0.25ZM5.25 9.75C5.25 9.8163 5.27634 9.87989 5.32322 9.92678C5.37011 9.97366 5.4337 10 5.5 10H8.5C8.5663 10 8.62989 9.97366 8.67678 9.92678C8.72366 9.87989 8.75 9.8163 8.75 9.75V7.25H5.25V9.75ZM0.5 7.5V9C0.5 9.26522 0.605357 9.51957 0.792893 9.70711C0.98043 9.89464 1.23478 10 1.5 10H4C4.0663 10 4.12989 9.97366 4.17678 9.92678C4.22366 9.87989 4.25 9.8163 4.25 9.75V7.25H0.75C0.683696 7.25 0.620107 7.27634 0.573223 7.32322C0.526339 7.37011 0.5 7.4337 0.5 7.5ZM0.5 4V6C0.5 6.0663 0.526339 6.12989 0.573223 6.17678C0.620107 6.22366 0.683696 6.25 0.75 6.25H4.25V3.75H0.75C0.683696 3.75 0.620107 3.77634 0.573223 3.82322C0.526339 3.87011 0.5 3.9337 0.5 4ZM8.5 0H5.5C5.4337 0 5.37011 0.0263393 5.32322 0.0732234C5.27634 0.120107 5.25 0.183696 5.25 0.25V2.75H8.75V0.25C8.75 0.183696 8.72366 0.120107 8.67678 0.0732234C8.62989 0.0263393 8.5663 0 8.5 0ZM13.25 3.75H9.75V6.25H13.25C13.3163 6.25 13.3799 6.22366 13.4268 6.17678C13.4737 6.12989 13.5 6.0663 13.5 6V4C13.5 3.9337 13.4737 3.87011 13.4268 3.82322C13.3799 3.77634 13.3163 3.75 13.25 3.75ZM5.25 6.25H8.75V3.75H5.25V6.25ZM12.5 0H10C9.9337 0 9.87011 0.0263393 9.82322 0.0732234C9.77634 0.120107 9.75 0.183696 9.75 0.25V2.75H13.25C13.3163 2.75 13.3799 2.72366 13.4268 2.67678C13.4737 2.62989 13.5 2.5663 13.5 2.5V1C13.5 0.734784 13.3946 0.48043 13.2071 0.292893C13.0196 0.105357 12.7652 0 12.5 0ZM13.25 7.25H9.75V9.75C9.75 9.8163 9.77634 9.87989 9.82322 9.92678C9.87011 9.97366 9.9337 10 10 10H12.5C12.7652 10 13.0196 9.89464 13.2071 9.70711C13.3946 9.51957 13.5 9.26522 13.5 9V7.5C13.5 7.4337 13.4737 7.37011 13.4268 7.32322C13.3799 7.27634 13.3163 7.25 13.25 7.25Z"-->
    <!--              fill="#AB4ABA"-->
    <!--            />-->
    <!--          </svg>-->
    <!--        </span>-->
    <!--        Xem chi tiết-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    @error="onError"-->
    <video
      :poster="currentPosterUrl"
      :src="currentVideoUrl"
      ref="videoEl"
      @ended="handleVideoEnd"
      :muted="isMobile ? state.isMuted : position.index == indexCurrent ? state.isMuted : true"
      x5-video-player-type="h5-page"
      :x5-video-player-fullscreen="false"
      :webkit-playsinline="true"
      :x5-playsinline="true"
      loop
      :playsinline="true"
      @canplay="onCanPlay"
      :fullscreen="false"
      @pause="onPause"
      :autoplay="isMobile ? isPlay : position.index == indexCurrent ? isPlay : false"
    >
      <!--        <p>您的浏览器不支持 video 标签。</p>-->
    </video>
    <Icon icon="fluent:play-28-filled" class="pause-icon" v-if="!isPlaying" />
    <div class="float">
      <template v-if="isLive">
        <div class="living"></div>
        <ItemDesc :is-live="true" v-model:item="state.localItem" :position="position" />
      </template>
      <template v-else>
        <div :style="{ opacity: state.isMove ? 0 : 1 }" class="normal">
          <template v-if="!state.commentVisible">
            <ItemToolbar v-model:item="state.localItem" :positionBar="position" />
            <ItemDesc v-model:item="state.localItem" :position="position" />
          </template>
          <div v-if="isMy" class="comment-status">
            <div class="comment">
              <div class="type-comment">
                <img src="../../assets/img/icon/head-image.jpeg" alt="" class="avatar" />
                <div class="right">
                  <p>
                    <span class="name">zzzzz</span>
                    <span class="time">2020-01-20</span>
                  </p>
                  <p class="text"></p>
                </div>
              </div>
              <transition-group name="comment-status" tag="div" class="loveds">
                <div class="type-loved" :key="i" v-for="i in state.test">
                  <img src="../../assets/img/icon/head-image.jpeg" alt="" class="avatar" />
                  <img src="../../assets/img/icon/love.svg" alt="" class="loved" />
                </div>
              </transition-group>
            </div>
          </div>
        </div>
        <div
          class="progress"
          :class="progressClass"
          ref="progressEl"
          @click="null"
          @touchstart="touchstart"
          @touchmove="touchmove"
          @touchend="touchend"
        >
          <div class="time" v-if="state.isMove">
            <span class="currentTime">{{ _duration(state.currentTime) }}</span>
            <span class="duration"> / {{ _duration(state.duration) }}</span>
          </div>
          <template v-if="state.duration > 15 || state.isMove || !isPlaying">
            <div class="bg"></div>
            <div class="progress-line" :style="durationStyle"></div>
            <div class="point"></div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { _duration, _stopPropagation } from '@/utils'
import Loading from '../Loading.vue'
import ItemToolbar from './ItemToolbar.vue'
import ItemDesc from './ItemDesc.vue'
import bus, { EVENT_KEY } from '../../utils/bus'
import { SlideItemPlayStatus } from '@/utils/const_var'
import { computed, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue'
import { Icon } from '@iconify/vue'
import { _css } from '@/utils/dom'
import { useClick } from '@/utils/hooks/useClick'

let lastTouchEnd = 0

document.addEventListener(
  'touchend',
  (event) => {
    const now = new Date().getTime()
    if (now - lastTouchEnd <= 300) {
      event.preventDefault()
    }
    lastTouchEnd = now
  },
  false
)

defineOptions({
  name: 'BaseVideo'
})
const vClick = useClick()
const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {}
    }
  },
  position: {
    type: Object,
    default: () => {
      return {}
    }
  },
  //用于第一条数据，自动播放，如果都用事件去触发播放，有延迟
  isPlay: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  isMy: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  isLive: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  index: {
    type: Number,
    default: () => {
      return 0
    }
  },
  id: {
    type: [String, Number],
    default: () => {
      return ''
    }
  },
  active: {
    type: Number,
    default: 0
  }
})

provide(
  'isPlaying',
  computed(() => isPlaying.value)
)
provide(
  'isMuted',
  computed(() => state.isMuted)
)
provide(
  'position',
  computed(() => props.position)
)
provide(
  'item',
  computed(() => props.item)
)
const checkIfMobile = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const mobileDevices =
    /(android|iphone|ipad|ipod|blackberry|bb|playbook|windows phone|webos|opera mini|opera mobi|palm os|palm|hiptop|avantgo|blazer|iemobile|midp|up\.browser|up\.link|docomo|samsung|symbian|sony|nokia|windows ce|phone|tablet|kindle|silk|mobile|pda|psp)/i
  return mobileDevices.test(userAgent)
}
let indexCurrent = -1
const isMobile = checkIfMobile()
const videoEl = _ref<HTMLVideoElement>()
const progressEl = _ref<HTMLDivElement>()
let state = reactive({
  index: -1,
  loading: false,
  paused: false,
  isMuted: window.isMuted,
  status: props.isPlay ? SlideItemPlayStatus.Play : SlideItemPlayStatus.Pause,
  duration: 0,
  step: 0,
  currentTime: -1,
  playX: 0,
  start: { x: 0 },
  last: { x: 0, time: 0 },
  height: 0,
  width: 0,
  isMove: false,
  ignoreWaiting: false, //忽略waiting事件。因为改变进度会触发waiting事件，烦的一批
  test: [1, 2],
  localItem: props.item,
  progressBarRect: {
    height: 0,
    width: 0
  },
  videoScreenHeight: 0,
  commentVisible: false,
  commentChat: false,
  removeMutedClick: false
})
// const poster = $computed(() => {
//   // return _checkImgUrl(props.item.video.poster ?? props.item.video.cover.url_list[0])
//   return _checkImgUrl(props.item.video.poster ?? props.item.video.poster)
// })
const durationStyle = _computed(() => {
  return { width: state.playX + 'px' }
})
const isPlaying = _computed(() => {
  return state.status === SlideItemPlayStatus.Play
})
const positionName = _computed(() => {
  return 'item-' + Object.values(props.position).join('-')
})

const progressClass = _computed(() => {
  if (state.isMove) {
    return 'move'
  } else {
    return isPlaying.value ? '' : 'stop'
  }
})
const currentUrlIndex = ref(0)
const currentVideoUrl =
  props.position.uniqueId === 'bat-dong-san'
    ? ref(props.item.video?.trailer)
    : ref(props.item.video.play_addr.url_list[currentUrlIndex.value])
const currentPosterUrl = ref(props.item.video?.poster)

const onError = () => {
  if (currentUrlIndex.value < props.item.video.play_addr.url_list.length - 1) {
    currentUrlIndex.value++
    currentVideoUrl.value = props.item.video.play_addr.url_list[currentUrlIndex.value]
    // Tải lại video
    if (videoEl.value) {
      videoEl.value.load()
      state.isMuted = true
      videoEl.value.play().catch((error) => {
        console.error('Error playing video:', error)
      })
    }
  } else {
    console.error('All video URLs failed to load.')
  }
}

const checkHideViewDetail = () => {
  if (props.item?.codeType === 'tvc') {
    return false
  } else {
    return true
  }
}

watch(currentVideoUrl, (newUrl) => {
  if (videoEl.value) {
    videoEl.value.src = newUrl
    videoEl.value.load()
    videoEl.value.play().catch((error) => {
      console.error('Error playing video:', error)
    })
  }
})

const onPause = () => {}

const onCanPlay = () => {
  // const isApp = navigator.userAgent.includes('flutter-app')
  if (indexCurrent === props.index) {
    // if (!isMobile) state.isMuted = false
    videoEl.value.play()
  } else {
    if (videoEl.value) videoEl.value.pause()
  }
}

const handleVideoEnd = () => {
  pause()
}

onMounted(() => {
  state.height = document.body.clientHeight
  state.width = document.body.clientWidth
  videoEl.value.currentTime = 0
  let fun = (e) => {
    state.currentTime = Math.ceil(e.target.currentTime)
    state.playX = (state.currentTime - 1) * state.step
    if (state.loading) state.loading = false
  }
  videoEl.value.addEventListener('loadedmetadata', () => {
    state.videoScreenHeight = videoEl.value.videoHeight / (videoEl.value.videoWidth / state.width)
    state.duration = videoEl.value.duration
    state.progressBarRect = progressEl.value.getBoundingClientRect()
    state.step = state.progressBarRect.width / Math.floor(state.duration)
    videoEl.value.addEventListener('timeupdate', fun)
  })

  let eventTester = (e, t: string) => {
    videoEl.value.addEventListener(
      e,
      () => {
        state.loading = true
        // console.log('eventTester', e, state.item.aweme_id)
        // if (e === 'playing')
        if (e === 'waiting') {
          if (!state.paused && !state.ignoreWaiting) {
            state.loading = true
          }
        }
        let s = false
        if (s) {
          console.log(e, t)
        }
      },
      false
    )
  }

  // eventTester("loadstart", '客户端开始请求数据'); //客户端开始请求数据
  // eventTester("abort", '客户端主动终止下载（不是因为错误引起）'); //客户端主动终止下载（不是因为错误引起）
  // eventTester("loadstart", '客户端开始请求数据'); //客户端开始请求数据
  // eventTester("progress", '客户端正在请求数据'); //客户端正在请求数据
  // // eventTester("suspend", '延迟下载'); //延迟下载
  // eventTester("abort", '客户端主动终止下载（不是因为错误引起），'); //客户端主动终止下载（不是因为错误引起），
  // eventTester("error", '请求数据时遇到错误'); //请求数据时遇到错误
  // eventTester("stalled", '网速失速'); //网速失速
  // eventTester("play", 'play()和autoplay开始播放时触发'); //play()和autoplay开始播放时触发
  // eventTester("pause", 'pause()触发'); //pause()触发
  // eventTester("loadedmetadata", '成功获取资源长度'); //成功获取资源长度
  // eventTester("loadeddata"); //
  eventTester('waiting', '等待数据，并非错误') //等待数据，并非错误
  eventTester('playing', '开始回放') //开始回放
  // eventTester("canplay", '/可以播放，但中途可能因为加载而暂停'); //可以播放，但中途可能因为加载而暂停
  // eventTester("canplaythrough", '可以播放，歌曲全部加载完毕'); //可以播放，歌曲全部加载完毕
  // eventTester("seeking", '寻找中'); //寻找中
  // eventTester("seeked", '寻找完毕'); //寻找完毕
  // // eventTester("timeupdate",'播放时间改变'); //播放时间改变
  // eventTester("ended", '播放结束'); //播放结束
  // eventTester("ratechange", '播放速率改变'); //播放速率改变
  // eventTester("durationchange", '资源长度改变'); //资源长度改变
  // eventTester("volumechange", '音量改变'); //音量改变

  // console.log('mounted')
  // bus.off('singleClickBroadcast')
  bus.on(EVENT_KEY.CURRENT_ITEM, updateMuted)
  bus.on(EVENT_KEY.SINGLE_CLICK_BROADCAST, click)
  bus.on(EVENT_KEY.DIALOG_MOVE, onDialogMove)
  bus.on(EVENT_KEY.DIALOG_END, onDialogEnd)
  bus.on(EVENT_KEY.OPEN_COMMENTS, onOpenComments)
  bus.on(EVENT_KEY.CLOSE_COMMENTS, onCloseComments)
  // bus.on(EVENT_KEY.OPEN_CHATS, onOpenChats)
  // bus.on(EVENT_KEY.CLOSE_CHATS, onCloseChats)
  bus.on(EVENT_KEY.OPEN_SUB_TYPE, onOpenSubType)
  bus.on(EVENT_KEY.CLOSE_SUB_TYPE, onCloseSubType)

  bus.on(EVENT_KEY.REMOVE_MUTED, removeMuted)
  bus.on(EVENT_KEY.PAUSE_ALL_WITHOUT_ID, pauseAllWithoutId)
})
watch(
  () => props.active,
  () => {
    if (props.isPlay) {
      indexCurrent = props.index
      bus.emit(EVENT_KEY.PAUSE_ALL_WITHOUT_ID, props)
    }
  },
  { immediate: true }
)

onUnmounted(() => {
  // Reset element video
  // if (videoEl) {
  //   videoEl.src = ''
  //   videoEl.load()
  // }

  bus.off(EVENT_KEY.CURRENT_ITEM, updateMuted)
  bus.off(EVENT_KEY.SINGLE_CLICK_BROADCAST, click)
  bus.off(EVENT_KEY.DIALOG_MOVE, onDialogMove)
  bus.off(EVENT_KEY.DIALOG_END, onDialogEnd)
  bus.off(EVENT_KEY.OPEN_COMMENTS, onOpenComments)
  bus.off(EVENT_KEY.CLOSE_COMMENTS, onCloseComments)
  // bus.off(EVENT_KEY.OPEN_CHATS, onOpenChats)
  // bus.off(EVENT_KEY.CLOSE_CHATS, onOpenChats)
  bus.off(EVENT_KEY.OPEN_SUB_TYPE, onOpenSubType)
  bus.off(EVENT_KEY.CLOSE_SUB_TYPE, onCloseSubType)
  bus.off(EVENT_KEY.REMOVE_MUTED, removeMuted)
  bus.off(EVENT_KEY.PAUSE_ALL_WITHOUT_ID, pauseAllWithoutId)
})
function removeMuted() {
  state.removeMutedClick = !state.removeMutedClick
  state.isMuted = !state.isMuted
  state.status = state.status === SlideItemPlayStatus.Pause ? 'Stop' : 'Play'
}

function onOpenSubType() {
  state.commentVisible = true
}

function onCloseSubType() {
  state.commentVisible = false
}

function onDialogMove({ tag, e }) {
  if (state.commentVisible && tag === 'comment') {
    _css(videoEl.value, 'transition-duration', `0ms`)
    _css(videoEl.value, 'height', `calc(var(--vh, 1vh) * 30 + ${e}px)`)
  }
}

function onDialogEnd({ tag, isClose }) {
  if (state.commentVisible && tag === 'comment') {
    _css(videoEl.value, 'transition-duration', `300ms`)
    if (isClose) {
      state.commentVisible = false
      _css(videoEl.value, 'height', '100%')
    } else {
      _css(videoEl.value, 'height', 'calc(var(--vh, 1vh) * 30)')
    }
  }
}

function onOpenComments(id) {
  if (id === props.item.aweme_id) {
    _css(videoEl.value, 'transition-duration', `300ms`)
    // _css(videoEl, 'height', 'calc(var(--vh, 1vh) * 30)')
    state.commentVisible = true
  }
}

function onCloseComments() {
  if (state.commentVisible) {
    // _css(videoEl, 'transition-duration', `300ms`)
    _css(videoEl.value, 'height', '100%')
    state.commentVisible = false
  }
}

function onOpenChats() {
  _css(videoEl.value, 'transition-duration', `300ms`)
  _css(videoEl.value, 'height', 'calc(var(--vh, 1vh) * 30)')
  state.commentChat = true
}

function onCloseChats() {
  if (state.commentChat) {
    _css(videoEl.value, 'transition-duration', `300ms`)
    _css(videoEl.value, 'height', '100%')
    state.commentChat = false
  }
}

function updateMuted({ ...item }) {
  state.loading = false
}
function click({ uniqueId, index, type, id = null, newVal = null, oldVal = null, isNext = false }) {
  state.index = index
  if (isNext) {
    indexCurrent = newVal
  }
  if (props.position.uniqueId === uniqueId && props.position.index === index) {
    if (type === EVENT_KEY.ITEM_TOGGLE) {
      if (props.isLive) {
        pause()
        bus.emit(EVENT_KEY.NAV, {
          path: '/home/live',
          query: { id: props.item.aweme_id }
        })
      } else {
        if (state.status === SlideItemPlayStatus.Play) {
          state.isMuted = true
          state.status = 'Stop'
          pause()
        } else {
          state.isMuted = false
          state.status = 'Play'
          play()
        }
      }
    }
    if (type === EVENT_KEY.ITEM_STOP) {
      videoEl.value.currentTime = 0
      state.ignoreWaiting = true
      pause()
      setTimeout(() => (state.ignoreWaiting = false), 300)
    }
    if (type === EVENT_KEY.ITEM_PLAY) {
      videoEl.value.currentTime = 0
      state.ignoreWaiting = true
      play()
      setTimeout(() => (state.ignoreWaiting = false), 300)
    }
  }
}

function play() {
  state.loading = false
  state.status = SlideItemPlayStatus.Play
  videoEl.value.volume = 1
  // videoEl.play()
  const playPromise = videoEl.value.play()
  if (playPromise !== undefined) {
    playPromise.catch((error) => {
      if (error.name === 'NotAllowedError') {
        // Yêu cầu phát bị từ chối do chính sách của trình duyệt
        state.isMuted = true
        videoEl.value.load()
        play()
      }
    })
  }
}

function pause() {
  state.status = SlideItemPlayStatus.Pause
  videoEl.value.pause()
}

function touchstart(e) {
  _stopPropagation(e)
  state.start.x = e.touches[0].pageX
  state.last.x = state.playX
  state.last.time = state.currentTime
}

function touchmove(e) {
  // console.log('move',e)
  _stopPropagation(e)
  state.isMove = true
  pause()
  let dx = e.touches[0].pageX - state.start.x
  state.playX = state.last.x + dx
  state.currentTime = state.last.time + Math.ceil(Math.ceil(dx) / state.step)
  if (state.currentTime <= 0) state.currentTime = 0
  if (state.currentTime >= state.duration) state.currentTime = state.duration
}

function pauseAllWithoutId(index) {
  if (props.index !== index) {
    if (videoEl.value) videoEl.value.pause()
  }
}
function touchend(e) {
  _stopPropagation(e)
  if (isPlaying.value) return
  setTimeout(() => (state.isMove = false), 1000)
  videoEl.value.currentTime = state.currentTime
  play()
}
</script>

<style scoped lang="less">
.video-background {
  // video {
  //   width: 100vh;
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   left: 0;
  //   height: auto !important;
  // }
  // position: relative;

  // &:before {
  //   content: '';
  //   height: 100vh;
  //   display: block;
  // }

  // video {
  //   z-index: 0;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 100%;
  //   height: 100%;
  //   -o-object-fit: cover;
  //   object-fit: cover;
  //   -webkit-transform: translate(-50%, -50%);
  //   -ms-transform: translate(-50%, -50%);
  //   transform: translate(-50%, -50%);
  // }
}

.video-wrapper {
  background: #000000; // manh thêm
  //display: flex; // manh tam bo
  align-items: center;
  //margin: auto;
  //position: relative;
  //height: 100%;
  //overflow: hidden;
  //max-width: 100%;
  //background: #000;

  video {
    max-width: 100%;
    width: 100%;
    height: auto;
    transition:
      height,
      margin-top 0.3s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    //background: black;
    object-fit: cover;
    max-height: 100%;
    //position: relative;
  }

  .float {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    .normal {
      position: absolute;
      bottom: 0;
      width: 100%;
      transition: all 0.3s;

      .comment-status {
        display: flex;
        align-items: center;

        .comment {
          .type-comment {
            display: flex;
            background: rgb(130, 21, 44);
            border-radius: 50px;
            padding: 3px;
            margin-bottom: 20px;

            .avatar {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .right {
              margin: 0 10px;
              color: var(--second-text-color);

              .name {
                margin-right: 10px;
              }

              .text {
                color: white;
              }
            }
          }

          .loveds {
          }

          .type-loved {
            width: 40px;
            height: 40px;
            position: relative;
            margin-bottom: 20px;
            animation: test 1s;
            animation-delay: 0.5s;

            .avatar {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .loved {
              position: absolute;
              bottom: 0;
              left: 20px;
              width: 10px;
              height: 10px;
              background: red;
              padding: 3px;
              border-radius: 50%;
              border: 2px solid white;
            }
          }

          @keyframes test {
            from {
              display: block;
              transform: translate3d(0, 0, 0);
            }
            to {
              display: none;
              transform: translate3d(0, -60px, 0);
            }
          }
        }
      }
    }

    .progress {
      z-index: 10;
      @w: 90%;
      position: absolute;
      bottom: -1rem;
      height: 10rem;
      left: calc((100% - @w) / 2);
      width: @w;
      display: flex;
      align-items: flex-end;
      margin-bottom: 2rem;

      .time {
        position: absolute;
        z-index: 9;
        font-size: 24px;
        bottom: 50px;
        left: 0;
        right: 0;
        color: white;
        text-align: center;

        .duration {
          color: darkgray;
        }
      }

      @radius: 10rem;

      @h: 2rem;
      @tr: height 0.3s;

      .bg {
        transition: @tr;
        position: absolute;
        width: 100%;
        height: @h;
        background: #4f4f4f;
        border-radius: @radius;
      }

      @p: 50px;

      .progress-line {
        transition: @tr;
        height: calc(@h + 0.5rem);
        width: @p;
        border-radius: @radius 0 0 @radius;
        background: #777777;
        z-index: 1;
      }

      .point {
        transition: all 0.2s;
        width: @h+2;
        height: @h+2;
        border-radius: 50%;
        background: gray;
        z-index: 2;
        transform: translate(-1rem, 1rem);
      }
    }

    & .move {
      @h: 10rem;

      .bg {
        height: @h;
        background: var(--active-main-bg);
      }

      .progress-line {
        height: @h;
        background: var(--second-text-color);
      }

      .point {
        width: @h+2;
        height: @h+2;
        background: white;
      }
    }

    & .stop {
      @h: 4rem;

      .bg {
        height: @h;
      }

      .progress-line {
        height: @h;
        background: white;
      }

      .point {
        width: @h+2;
        height: @h+2;
        background: white;
      }
    }
  }
}

//.video-wrapper iframe,
//.video-wrapper object,
//.video-wrapper embed {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//}

.living {
  position: absolute;
  left: 50%;
  font-size: 18rem;
  border-radius: 50rem;
  border: 1px solid #e0e0e0;
  padding: 15rem 20rem;
  line-height: 1;
  color: white;
  top: 70%;
  transform: translate(-50%, -50%);
}
.back-transition {
  top: 15px !important;
  left: 15px !important;
}
.detail-transition {
}

.no-translate {
  .video-wrapper {
    video {
      position: static !important;
      transform: none !important;
    }
  }
}
</style>
