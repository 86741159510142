export default {
  DISPLAY_TYPE: {
    ALL: 0,
    SCHOOL: 1,
    ME: 2
  },
  TRIGGER_TIME: {
    TIME40: 40,
    TIME60: 60,
    TIME90: 90,
    TIME120: 120
  }
}
