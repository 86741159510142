export const COLLECTION_NAMES = {
  PROJECT: 'project',
  TANO: 'tano',
  CATEGORY: 'category',
  UTILITY: 'utility',
  COMMENT: 'comment',
  REPLIES: 'replies',
  APARTMENT: 'apartment',
  PRODUCT: 'product',
  SUBDEVISION: 'subdevision'
}

export const AUTHENS = {
  AUTHEN_DATA: 'ss_tt_authen_data',
  TOKEN: 'ss_tt_authToken',
  REFRESH_TOKEN: 'ss_tt_refresh_token'
}

export const SCREENS = {
  FOTGET_PASSWORD: 'ForgetPassword',
  REGISTER: 'Register'
}
