<script lang="tsx">
const itemClassName = 'slide-item'
</script>
<script setup lang="tsx">
import { createApp, onMounted, reactive, ref, render as vueRender, watch } from 'vue'
import {
  getSlideOffset,
  slideInit,
  slideReset,
  slideTouchEnd,
  slideTouchMove,
  slideTouchStart
} from '@/utils/slide'
import { SlideType } from '@/utils/const_var'
import SlideItem from '@/components/slide/SlideItem.vue'
import bus, { EVENT_KEY } from '../../utils/bus'
import Loading from '@/components/Loading.vue'
import { useBaseStore } from '@/store/pinia'
import { _css } from '@/utils/dom'

const props = defineProps({
  index: {
    type: Number,
    default: () => {
      return -1
    }
  },
  render: {
    type: Function,
    default: () => {
      return null
    }
  },
  list: {
    type: Array,
    default: () => {
      return []
    }
  },
  //页面中同时存在多少个SlideItem
  virtualTotal: {
    type: Number,
    default: () => 100
  },
  name: {
    type: String,
    default: () => ''
  },
  uniqueId: {
    type: String,
    default: () => ''
  },
  loading: {
    type: Boolean,
    default: () => false
  },
  active: {
    type: Boolean,
    default: () => false
  },
  infinite: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:index', 'loadMore', 'refresh'])

const appInsMap = new Map()

const slideListEl = ref<HTMLDivElement>(null)
const state = reactive({
  judgeValue: 20,
  type: SlideType.VERTICAL_INFINITE,
  name: props.name,
  localIndex: props.index,
  needCheck: true,
  next: false,
  isDown: false,
  start: { x: 0, y: 0, time: 0 },
  move: { x: 0, y: 0 },
  wrapper: { width: 0, height: 0, childrenLength: 0 }
})
const baseStore = useBaseStore()

watch(
  () => props.list,
  (newVal, oldVal) => {
    // console.log('watch-list', newVal.length, oldVal.length, newVal)
    //新数据长度比老数据长度小，说明是刷新
    if (newVal.length < oldVal.length) {
      insertContent()
    } else {
      //没数据就直接插入
      if (oldVal.length === 0) {
        insertContent()
      } else {
        // insertContent()
        // 走到这里，说明是通过接口加载了下一页的数据，
        // 为了在用户快速滑动时，无需频繁等待请求接口加载数据，给用户更好的使用体验
        // 这里额外加载3条数据。所以此刻，html里面有原本的5个加新增的3个，一共8个dom
        // 用户往下滑动时只删除前面多余的dom，等滑动到临界值（virtualTotal/2+1）时，再去执行新增逻辑
        // let lastSlideItem = slideListEl.value.querySelector(`.${itemClassName}:last-child`)
        // let top = _css(lastSlideItem, 'top')
        // let newListStartIndex = Number(lastSlideItem.getAttribute('data-index')) + 1
        // // console.log('newListStartIndex', newListStartIndex)
        // newVal.slice(newListStartIndex, newListStartIndex + 3).map((item, index) => {
        //   let el = getInsEl(item, newListStartIndex + index)
        //   //这里必须要设置个top值，不然会把前面的条目给覆盖掉
        //   //2022-3-27，这里不用计算，直接用已用slide-item最后一条的top值，
        //   //因为有一条情况：当滑动最后一条和二条的时候top值不会继续加。此时新增的数据如果还
        //   // 计算top值的，会和前面的对不上
        //   _css(el, 'top', top)
        //   slideListEl.value.appendChild(el)
        //   state.wrapper.childrenLength++
        // })
      }
    }
  }
)
let availableIndexes = []

watch(
  () => props.index,
  (newVal, oldVal) => {
    state.localIndex = newVal
    if (!props.list.length) return
    bus.emit(EVENT_KEY.CURRENT_ITEM, props.list[newVal])
    bus.emit(EVENT_KEY.SINGLE_CLICK_BROADCAST, {
      uniqueId: props.uniqueId,
      index: newVal,
      type: EVENT_KEY.ITEM_PLAY,
      id: props.list[newVal].id,
      newVal: newVal,
      oldVal: null,
      isNext: true
    })
    setTimeout(() => {
      bus.emit(EVENT_KEY.SINGLE_CLICK_BROADCAST, {
        uniqueId: props.uniqueId,
        index: oldVal,
        type: EVENT_KEY.ITEM_STOP,
        id: props.list[newVal].id,
        newVal: null,
        oldVal: oldVal,
        isNext: false
      })
      if (props.uniqueId === 'bat-dong-san') {
        availableIndexes =
          props.list[oldVal].video.background && props.list[oldVal].video.background.length > 0
            ? [...Array(props.list[oldVal].video.background.length).keys()]
            : []
        availableIndexes =
          availableIndexes.length > 0 ? availableIndexes.sort(() => Math.random() - 0.5) : []
        const el = document.getElementsByClassName(`item-bat-dong-san-${oldVal}`)
        const background =
          props.list[oldVal] &&
          props.list[oldVal].video.background &&
          props.list[oldVal].video.background.length > 0
            ? getRandomBackground(oldVal)
            : null
        el[0].children[0].poster =
          props.list[oldVal] &&
          props.list[oldVal].video.background &&
          props.list[oldVal].video.background.length > 0
            ? getRandomBackground(oldVal).poster
            : el[0].children[0].poster
        el[0].children[0].poster =
          background && background.poster ? background.poster : el[0].children[0].poster
        el[0].children[0].src =
          background && background.trailer ? background.trailer : el[0].children[0].src
      }
    }, 200)
  }
)

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    // Chọn chỉ số ngẫu nhiên từ 0 đến i
    const j = Math.floor(Math.random() * (i + 1))

    // Hoán đổi các phần tử
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

function resetAvailableIndexes(oldVal) {
  availableIndexes = [...Array(props.list[oldVal].video.background.length).keys()]
  availableIndexes =
    availableIndexes.length > 0 ? availableIndexes.sort(() => Math.random() - 0.5) : []
}

function getRandomBackground(oldVal) {
  if (availableIndexes.length === 0) {
    console.log('Đã hiển thị tất cả các phần tử. Bây giờ sẽ làm mới các chỉ số.')
    resetAvailableIndexes(oldVal) // Làm mới chỉ số khi đã hiển thị hết
  }

  // Chọn một chỉ số ngẫu nhiên từ availableIndexes
  const randomIndex = Math.floor(Math.random() * availableIndexes.length)
  const selectedIndex = availableIndexes[randomIndex]

  // Lấy phần tử và loại bỏ chỉ số vừa chọn
  const selectedBackground = props.list[oldVal].video.background[selectedIndex]
  availableIndexes.splice(randomIndex, 1) // Xóa chỉ số đã chọn
  return selectedBackground
}

watch(
  () => props.active,
  (newVal) => {
    if (newVal && !props.list.length) {
      return emit('refresh')
    }
    let t = newVal ? 0 : 200
    // console.log('active', 'newVal', newVal, 'oldVal', oldVal)
    if (newVal) {
      bus.emit(EVENT_KEY.CURRENT_ITEM, props.list[state.localIndex])
    }
    setTimeout(() => {
      bus.emit(EVENT_KEY.SINGLE_CLICK_BROADCAST, {
        uniqueId: props.uniqueId,
        index: state.localIndex,
        type: newVal === false ? EVENT_KEY.ITEM_STOP : EVENT_KEY.ITEM_PLAY
      })
    }, t)
  },
  { immediate: true }
)

onMounted(() => {
  slideInit(slideListEl.value, state)
})

/**
 * 插入SlideItem
 */
function insertContent() {
  if (!props.list.length) return
  slideListEl.value.innerHTML = ''
  let half = (props.virtualTotal - 1) / 2
  let start = 0
  if (state.localIndex > half) {
    start = state.localIndex - half
  }
  let end = start + props.virtualTotal
  if (end >= props.list.length) {
    end = props.list.length
    start = end - props.virtualTotal
  }
  if (start < 0) start = 0

  props.list.slice(start, end).map((item, index) => {
    let el = getInsEl(item, start + index, start + index === state.localIndex)
    slideListEl.value.appendChild(el)
  })

  _css(
    slideListEl.value,
    'transform',
    `translate3d(0px,${getSlideOffset(state, slideListEl.value)}px,  0px)`
  )

  if (state.localIndex > 100 && props.list.length > 100) {
    let list = slideListEl.value.querySelectorAll(`.${itemClassName}`)
    list.forEach((item) => {
      if (list.length - state.localIndex > 2) {
        _css(item, 'top', (state.localIndex - 2) * state.wrapper.height)
      } else {
        _css(item, 'top', start * state.wrapper.height)
      }
    })
  }
  state.wrapper.childrenLength = slideListEl.value.children.length
  bus.emit(EVENT_KEY.CURRENT_ITEM, props.list[state.localIndex])
}

function dislike() {
  // let currentItem = $(slideListEl.value).find(`.${itemClassName}[data-index=${state.localIndex}]`)
  // let replaceItem = getInsEl(item, state.localIndex, true)
  // $(replaceItem).css('top', currentItem.css('top'))
  // currentItem.replaceWith(replaceItem)
}

defineExpose({ dislike })

/**
 * 获取Vue组件渲染之后的dom元素
 * @param item
 * @param index
 * @param play
 */
function getInsEl(item, index, play = false) {
  // console.log('index', cloneDeep(item), index, play)
  let slideVNode = props.render(item, index, play, props.uniqueId)
  const parent = document.createElement('div')
  //TODO 打包到线上时用这个，这个在开发时任何修改都会刷新页面
  if (import.meta.env.PROD) {
    parent.classList.add('slide-item')
    parent.setAttribute('data-index', index)
    //将Vue组件渲染到一个div上
    vueRender(slideVNode, parent)
    appInsMap.set(index, {
      unmount: () => {
        vueRender(null, parent)
        parent.remove()
      }
    })
    return parent
  } else {
    //创建一个新的Vue实例，并挂载到一个div上
    const app = createApp({
      render() {
        return (
          <SlideItem data-index={index} class={state.localIndex === index ? 'actice' : ''}>
            {slideVNode}{' '}
          </SlideItem>
        )
      }
    })
    const ins = app.mount(parent)
    appInsMap.set(index, app)
    return ins.$el
  }
}

function touchStart(e) {
  slideTouchStart(e, slideListEl.value, state)
}

function touchMove(e) {
  slideTouchMove(e, slideListEl.value, state, canNext)
}

function touchEnd(e) {
  // Xác định hướng cuộn: nếu giá trị move.y nhỏ hơn 0, người dùng đang cuộn xuống; ngược lại, cuộn lên.
  let isNext = state.move.y < 0

  if (props.infinite) {
    // Chế độ cuộn vô hạn

    if (isNext) {
      // Nếu người dùng cuộn xuống
      if (state.localIndex >= props.list.length - 1) {
        // Nếu đang ở cuối danh sách
        state.localIndex = 0 // Quay lại đầu danh sách
        // insertContent() // Cập nhật nội dung danh sách
      } else {
        // Nếu chưa ở cuối danh sách
        state.localIndex++ // Di chuyển đến mục tiếp theo
        // insertContent() // Cập nhật nội dung danh sách
      }
    } else {
      // Nếu người dùng cuộn lên
      if (state.localIndex <= 0) {
        // Nếu đang ở đầu danh sách
        state.localIndex = props.list.length - 1 // Di chuyển đến mục cuối cùng của danh sách
        // insertContent() // Cập nhật nội dung danh sách
      } else {
        // Nếu chưa ở đầu danh sách
        state.localIndex-- // Di chuyển đến mục trước đó
        // insertContent() // Cập nhật nội dung danh sách
      }
    }
    // insertContent() // Cập nhật nội dung danh sách
    slideReset(e, slideListEl.value, state, emit)
  } else {
    // Chế độ không cuộn vô hạn

    if (
      state.localIndex === 0 &&
      !isNext &&
      state.move.y > baseStore.homeRefresh + baseStore.judgeValue
    ) {
      // Nếu đang ở đầu danh sách, cuộn lên và di chuyển đủ xa để yêu cầu làm mới dữ liệu
      emit('refresh') // Phát sự kiện làm mới dữ liệu
    }

    // Xử lý các trường hợp cuộn với chế độ không vô hạn
    slideTouchEnd(e, state, canNext, (isNext) => {
      let half = (props.virtualTotal - 1) / 2 // Tính toán một nửa số mục ảo

      if (props.list.length > props.virtualTotal) {
        if (isNext) {
          // Nếu cuộn xuống
          if (
            state.localIndex > props.list.length - props.virtualTotal &&
            state.localIndex > half
          ) {
            // Nếu chỉ số hiện tại lớn hơn số mục ảo và đã qua nửa danh sách
            emit('loadMore') // Phát sự kiện tải thêm dữ liệu
          }
          if (state.localIndex > half && state.localIndex < props.list.length - half) {
            // Nếu chỉ số hiện tại nằm trong khoảng giữa danh sách
            let addItemIndex = state.localIndex + half // Tính chỉ số của mục cần thêm
            let res = slideListEl.value.querySelector(
              `.${itemClassName}[data-index='${addItemIndex}']`
            )
            if (!res) {
              slideListEl.value.appendChild(getInsEl(props.list[addItemIndex], addItemIndex)) // Thêm mục vào danh sách nếu chưa có
            }
            let index = slideListEl.value
              .querySelector(`.${itemClassName}:first-child`)
              .getAttribute('data-index')
            appInsMap.get(Number(index)).unmount() // Hủy mục đầu tiên
            slideListEl.value.querySelectorAll(`.${itemClassName}`).forEach((item) => {
              _css(item, 'top', (state.localIndex - half) * state.wrapper.height) // Cập nhật vị trí của các mục
            })
          }
        } else {
          // Nếu cuộn lên
          if (state.localIndex >= half && state.localIndex < props.list.length - (half + 1)) {
            // Nếu chỉ số hiện tại nằm trong khoảng giữa danh sách
            let addIndex = state.localIndex - half // Tính chỉ số của mục cần thêm
            if (addIndex >= 0) {
              let res = slideListEl.value.querySelector(
                `.${itemClassName}[data-index='${addIndex}']`
              )
              if (!res) {
                slideListEl.value.prepend(getInsEl(props.list[addIndex], addIndex)) // Thêm mục vào đầu danh sách nếu chưa có
              }
            }
            let index = slideListEl.value
              .querySelector(`.${itemClassName}:last-child`)
              .getAttribute('data-index')
            appInsMap.get(Number(index)).unmount() // Hủy mục cuối cùng
            slideListEl.value.querySelectorAll(`.${itemClassName}`).forEach((item) => {
              _css(item, 'top', (state.localIndex - half) * state.wrapper.height) // Cập nhật vị trí của các mục
            })
          }
        }
        state.wrapper.childrenLength = slideListEl.value.children.length // Cập nhật số lượng mục con trong khung
      }
    })

    // Xử lý reset cho cuộn
    slideReset(e, slideListEl.value, state, emit)
  }
}

function canNext(state, isNext: boolean) {
  return !(
    (state.localIndex === 0 && !isNext) ||
    (state.localIndex === props.list.length - 1 && isNext)
  )
}
</script>

<template>
  <div class="slide slide-infinite">
    <Loading v-if="props.loading && props.list.length === 0" />
    <div
      class="slide-list flex-direction-column"
      ref="slideListEl"
      @pointerdown.prevent="touchStart"
      @pointermove.prevent="touchMove"
      @pointerup.prevent="touchEnd"
    >
      <slot></slot>
    </div>
  </div>
</template>
