import CONST_VAR from '../../utils/const_var'

export default {
  videos: [],
  my: [],
  users: {
    all: [
      {
        id: '224e9a00-ffa0-4bc1-bb07-c318c7b02fa5',
        avatar: new URL('../../assets/img/icon/avatar/1.png', import.meta.url).href,
        name: '何以为家',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: '234',
        pinyin: 'H',
        select: false,
        type: CONST_VAR.RELATE_ENUM.REQUEST_FOLLOW
      },
      {
        id: '2b446aa8-5d31-429a-8e39-d4705eb4e900',
        avatar: new URL('../../assets/img/icon/avatar/19.png', import.meta.url).href,
        name: '浅唱↘我们的歌',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: '33453',
        pinyin: 'Q',
        select: false,
        type: CONST_VAR.RELATE_ENUM.FOLLOW_ME
      },
      {
        id: '2d8622b4-7112-4bc7-a0cf-00a268a85504',
        avatar: new URL('../../assets/img/icon/avatar/25.png', import.meta.url).href,
        name: '‘心’之天空',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: '234234',
        pinyin: '#',
        select: false,
        type: CONST_VAR.RELATE_ENUM.FOLLOW_HE
      },
      {
        id: '2e38900b-65f7-426a-8e8b-edb403df35fb',
        avatar: new URL('../../assets/img/icon/avatar/18.png', import.meta.url).href,
        name: '◇、_保持微笑ゞ',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: '1111',
        pinyin: '#',
        select: false,
        type: CONST_VAR.RELATE_ENUM.FOLLOW_EACH_OTHER
      },
      {
        id: '3cd53cb5-04f8-4439-ad2b-e57b3f9b8c73',
        avatar: new URL('../../assets/img/icon/avatar/26.png', import.meta.url).href,
        name: '好◇°我会Yi直在●',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'sdfs',
        pinyin: 'H',
        select: false
      },
      {
        id: '46c77017-9dc6-4a48-bf96-560c6e7db06f',
        avatar: new URL('../../assets/img/icon/avatar/4.png', import.meta.url).href,
        name: '甫韦茹',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'dgfdfg',
        pinyin: 'F',
        select: false
      },
      {
        id: '4df770d7-a414-4e02-8f9e-72ebd87eb93e',
        avatar: new URL('../../assets/img/icon/avatar/12.png', import.meta.url).href,
        name: '幸福泡泡',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'df',
        pinyin: 'X',
        select: false
      },
      {
        id: '5ebb4d7a-c7ad-43c7-a830-9eb3ee423cb7',
        avatar: new URL('../../assets/img/icon/avatar/3.png', import.meta.url).href,
        name: '蔡傲安',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'ssss',
        pinyin: 'C',
        select: false
      },
      {
        id: '7226092d-a2c5-4ffd-98d7-3b7ac814bca3',
        avatar: new URL('../../assets/img/icon/avatar/16.png', import.meta.url).href,
        name: '心若向阳无谓伤悲',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'asdfasd',
        pinyin: 'X',
        select: false
      },
      {
        id: '72aef2a9-5573-4205-9288-706e19bccc7f',
        avatar: new URL('../../assets/img/icon/avatar/10.png', import.meta.url).href,
        name: 'A倒影着稚嫩的少年',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'wfghdf',
        pinyin: '#',
        select: false
      },
      {
        id: '77c82d2e-9be1-45c2-a733-00445d2e7f2c',
        avatar: new URL('../../assets/img/icon/avatar/22.png', import.meta.url).href,
        name: '一只喵的旅行。',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'sadf',
        pinyin: 'Y',
        select: false
      },
      {
        id: '7b30566d-70eb-4a31-ae43-200917269a43',
        avatar: new URL('../../assets/img/icon/avatar/14.png', import.meta.url).href,
        name: '℉阳光下的小情绪',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'asdfa',
        pinyin: '#',
        select: false
      },
      {
        id: '90c6ccd8-0653-4789-9348-28fba06d6ae3',
        avatar: new URL('../../assets/img/icon/avatar/11.png', import.meta.url).href,
        name: '思念一直在',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'as',
        pinyin: 'S',
        select: false
      },
      {
        id: 'a2c1310f-1a3c-430f-9b7c-557b9ccdd33d',
        avatar: new URL('../../assets/img/icon/avatar/9.png', import.meta.url).href,
        name: '阎韶丽',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'vbvd',
        pinyin: 'Y',
        select: false
      },
      {
        id: 'a7443b4f-b0fa-4409-a693-5f090aa763ee',
        avatar: new URL('../../assets/img/icon/avatar/5.png', import.meta.url).href,
        name: '马佳婉清',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'sdf',
        pinyin: 'M',
        select: false
      },
      {
        id: 'b49fd828-f8a7-4be0-a3bc-0a8fa312b2e8',
        avatar: new URL('../../assets/img/icon/avatar/0.png', import.meta.url).href,
        name: '倒影着稚嫩的少年',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'eeee',
        pinyin: 'D',
        select: false
      },
      {
        id: 'b632bffe-1cfb-408a-8255-b558e2faf49a',
        avatar: new URL('../../assets/img/icon/avatar/8.png', import.meta.url).href,
        name: '买易槐',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'xczxc',
        pinyin: 'M',
        select: false
      },
      {
        id: 'c01f10dd-a270-42ab-876f-64a8beb22a69',
        avatar: new URL('../../assets/img/icon/avatar/7.png', import.meta.url).href,
        name: '章昊苍',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'gggggggggr',
        pinyin: 'Z',
        select: false
      },
      {
        id: 'd0bf11a3-a25b-4865-a047-c67de8e8af7b',
        avatar: new URL('../../assets/img/icon/avatar/2.png', import.meta.url).href,
        name: '凌依晨',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: '12',
        pinyin: 'L',
        select: false
      },
      {
        id: 'd300b7a5-115d-41ba-a071-7d1781da331f',
        avatar: new URL('../../assets/img/icon/avatar/23.png', import.meta.url).href,
        name: '为你锁住心',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: '1629993515',
        pinyin: 'W',
        select: false
      },
      {
        id: 'deceba30-80fc-45c2-bd56-8d65091bb104',
        avatar: new URL('../../assets/img/icon/avatar/13.png', import.meta.url).href,
        name: '原味青春',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'werwe',
        pinyin: 'Y',
        select: false
      },
      {
        id: 'e0c3d20d-5978-4339-962f-cffc6e29aff1',
        avatar: new URL('../../assets/img/icon/avatar/24.png', import.meta.url).href,
        name: '一起走过的日子°',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'dfgdfg',
        pinyin: 'Y',
        select: false
      },
      {
        id: 'e1fb6274-10fa-4d50-b9b1-6eefacfc2341',
        avatar: new URL('../../assets/img/icon/avatar/15.png', import.meta.url).href,
        name: '看，熟悉旳风景',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'aasdasd',
        pinyin: 'K',
        select: false
      },
      {
        id: 'eab1ef9b-70e6-4249-8d78-b4f6e1b0cb08',
        avatar: new URL('../../assets/img/icon/avatar/20.png', import.meta.url).href,
        name: 'dear°学会微笑',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'gggggg',
        pinyin: '#',
        select: false
      },
      {
        id: 'ecb405e2-d057-4c85-a12b-a5fe9a7b8a05',
        avatar: new URL('../../assets/img/icon/avatar/6.png', import.meta.url).href,
        name: '富察昕昕',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'ghjg',
        pinyin: 'F',
        select: false
      },
      {
        id: 'f09652a3-d6e1-4517-a15e-583a24a250e1',
        avatar: new URL('../../assets/img/icon/avatar/17.png', import.meta.url).href,
        name: '蓝天下的迷彩?',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'yhjh',
        pinyin: 'L',
        select: false
      },
      {
        id: 'f676cc30-cc81-487a-9450-cbb1f3c5cde8',
        avatar: new URL('../../assets/img/icon/avatar/21.png', import.meta.url).href,
        name: '伱的微笑',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'ghj',
        pinyin: 'N',
        select: false
      }
    ],
    recent: [
      {
        id: '3cd53cb5-04f8-4439-ad2b-e57b3f9b8c73',
        avatar: new URL('../../assets/img/icon/avatar/26.png', import.meta.url).href,
        name: '好◇°我会Yi直在●',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'sdfs',
        pinyin: 'H',
        select: false
      },
      {
        id: '77c82d2e-9be1-45c2-a733-00445d2e7f2c',
        avatar: new URL('../../assets/img/icon/avatar/22.png', import.meta.url).href,
        name: '一只喵的旅行。',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'sadf',
        pinyin: 'Y',
        select: false
      }
    ],
    eachOther: [
      {
        id: '2b446aa8-5d31-429a-8e39-d4705eb4e900',
        avatar: new URL('../../assets/img/icon/avatar/19.png', import.meta.url).href,
        name: '浅唱↘我们的歌',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: '33453',
        pinyin: 'Q',
        select: false
      },
      {
        id: 'eab1ef9b-70e6-4249-8d78-b4f6e1b0cb08',
        avatar: new URL('../../assets/img/icon/avatar/20.png', import.meta.url).href,
        name: 'dear°学会微笑',
        sex: '',
        age: 25,
        idCard: null,
        phone: '',
        address: null,
        wechat: '',
        password: null,
        lastLoginTime: '1629993515',
        createTime: '1630035089',
        isDelete: 0,
        account: 'gggggg',
        pinyin: '#',
        select: false
      }
    ]
  },
  music: [
    {
      name: '龙卷风',
      mp3: 'http://im5.tongbu.com/rings/singerring/zt_uunGo_1/5605.mp3',
      cover: new URL('../../assets/img/music-cover/1.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 99,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '爱在西元前',
      mp3: 'http://m3.8js.net:99/1916/501204165042405.mp3',
      cover: new URL('../../assets/img/music-cover/2.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '蜗牛',
      mp3: 'http://im5.tongbu.com/rings/singerring/zt_uunGo_1/3684.mp3',
      cover: new URL('../../assets/img/music-cover/3.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '半岛铁盒',
      mp3: 'http://m3.8js.net:99/2016n/46/94745.mp3',
      cover: new URL('../../assets/img/music-cover/4.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '轨迹',
      mp3: 'http://m3.8js.net:99/1832/411204324135934.mp3',
      cover: new URL('../../assets/img/music-cover/5.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '七里香',
      mp3: 'http://m3.8js.net:99/2016n/14/53717.mp3',
      cover: new URL('../../assets/img/music-cover/6.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '发如雪',
      mp3: 'http://m3.8js.net:99/2014/211204142150965.mp3',
      cover: new URL('../../assets/img/music-cover/7.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '霍元甲',
      mp3: 'http://m3.8js.net:99/1921/261204212643140.mp3',
      cover: new URL('../../assets/img/music-cover/8.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '千里之外(周杰伦/费玉清)',
      mp3: 'http://im5.tongbu.com/rings/singerring/zt_uunGo_1/121.mp3',
      cover: new URL('../../assets/img/music-cover/9.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '菊花台',
      mp3: 'http://im5.tongbu.com/rings/singerring/zt_uunGo_1/2022.mp3',
      cover: new URL('../../assets/img/music-cover/10.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '不能说的秘密',
      mp3: 'http://im5.tongbu.com/rings/singerring/zt_uunGo_1/165.mp3',
      cover: new URL('../../assets/img/music-cover/11.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '牛仔很忙',
      mp3: 'http://im5.tongbu.com/rings/singerring/zt_uunGo_1/219.mp3',
      cover: new URL('../../assets/img/music-cover/12.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '给我一首歌的时间',
      mp3: 'http://m3.8js.net:99/1938/041204380445445.mp3',
      cover: new URL('../../assets/img/music-cover/18.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '烟花易冷',
      mp3: 'http://m3.8js.net:99/1828/051204280535192.mp3',
      cover: new URL('../../assets/img/music-cover/14.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '惊叹号',
      mp3: 'http://m3.8js.net:99/20111103/150.mp3',
      cover: new URL('../../assets/img/music-cover/15.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '明明就',
      mp3: 'http://m3.8js.net:99/2016n/27/96537.mp3',
      cover: new URL('../../assets/img/music-cover/16.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '算什么男人',
      mp3: 'http://m3.8js.net:99/20150107/429.mp3',
      cover: new URL('../../assets/img/music-cover/17.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    },
    {
      name: '告白气球',
      mp3: 'http://m3.8js.net:99/20161016/481.mp3',
      cover: new URL('../../assets/img/music-cover/18.jpg', import.meta.url).href,
      author: '周杰伦',
      duration: 60,
      use_count: 37441000,
      is_collect: false,
      is_play: false
    }
  ]
}
